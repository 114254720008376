export default {
  tabbar: {
    首页: '首頁',
    市场: '市場',
    任务: '任務',
    团队: '團隊',
    我的: '我的'
  },
  navbar: {
    充值: '充值',
    提现: '提現',
    帮助: 'FAQ',
    分享: '邀請',
	推广: '推廣',
    活动: '獎金',
    游戏: '遊戲',
  },	 
  payment: {
    付款方式详情: '付款方式詳情',
    银行卡: '銀行卡',
    钱包地址: '錢包地址',
    交易密码: '交易密碼',
    保存: '保存'
  },
  market: {
    市场: '市場',
    名称: '名稱',
    价格: '價格',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: '我知道了'
  },
  邀请好友注册领取佣金:"邀請好友註冊領取佣金",
  领取:"領取",
  未满足条件:"未滿足條件",
  总收入:"總收入",
  投资建议:"※进行复利投资计划，收益更可观",
  语言切换: '語言切換',
  POS区块节点挖矿: '人工智慧量化交易詳情',
  节点类型: '機器人\n類型',
  加入价格: '購買\n價格',
  质押资金: '質押\n資金',
  质押周期: '週期\n(天)',
  每日收入: '每日\n收入',
  累计积分: '累計\n積分',
  代理等级: '代理\n等級',
  下级折扣比: '下級\n折扣',
  代理折扣比: '代理\n折扣',
  下属等级: '下屬\n等級',
  代理佣金比率: '代理\n佣金\n比率',
  下属每日收入: '下屬\n每日\n收入',
  代理佣金: '代理\n佣金',
  引用对象: '引用\n對象',
  积分等级折扣规则: '積分等級折扣規則',
  积分等级折扣规则介绍: '成功參與Ai量化交易的用戶將獲得對應的帳戶積分，並且他們的代理上級用戶也可以增加對應的積分（適用於直屬下屬15%），當帳戶總積分滿足對應條件後代理帳號的等級將會提升 ，不同等級的代理帳號可以獲得不同比例的價格折扣優惠。 相關積分折扣詳情如下：',
  积分等级折扣规则介绍2: '具有不同關係（直接）的用戶將收到根據比例給予下屬參與Ai量化交易的折扣和代理級折扣。 其他高層也將獲得根據自己代理商相應比例的折扣等級。 參與Ai量化交易價格優惠：擁有對應代理等級的用戶參與Ai量化交易時將獲得相應的代理級優惠權利',
  团队: '團隊',
  代理佣金制度: '代理佣金制度',
  代理佣金说明详情: 'A獲得的LV1代理比例：8%，A獲得的LV2代理比例：3%，A獲得的LV3代理比例：2%。例子：B、C、D每日盈利為：100 USD，A獲得的LV1代理獎勵：8%*100=8 USD。 A獲得的LV2代理獎勵：3%*100=3 USD。 A獲得的LV3代理獎勵：2%*100=2 USD。總計 佣金收入為：8+3+2=13 USD。',
  代理结构图示: '代理結構圖示',
  代理结构图示说明: 'A代表你， A邀請B(A的下屬是B)，B邀請C(B的下屬是C)，C邀請D(C的下屬是D)。 A可以有多個下屬(B1,B2,B3,B4,B5,B6,etc.)，B(B1,B2,B3) 也可能有多個下屬(C1,c2,c3,c4,c5,c6,etc.) C... D...',
  高级代理佣金说明: '高級代理佣金說明',
  高级代理佣金说明详情: 'A 代表你， A邀請B(A 的下屬是 B)， B 邀請 C(B 的下屬是C)， C 邀請 D (C的下屬是D) 。上級代理將根據本身賬號和下屬的賬號參與節點挖礦的總金額 比率獲得不同的代理佣金收入(%)。 A對比下屬成員每日收入獲得代理獎勵相應的比例。參與的節點必須處於正常活動狀態。代理收入由INGSAI MINING提供所有財務支出，並不會影響正常下屬成員的每日收入。',
  高级代理佣金说明详情2: '例子：高級代理總參與挖礦金額(A)>30%下層會員總挖礦金額(B/C/D)(例：A>30% of B/C/D)',
  积分: '積分',
  规则: '規則',
  会员级别: '會員級別',
  经验: '積分總值',
  代理信息: '成員信息',
  更多: '更多',
  团队奖励: '團隊佣金',
  昨天: '昨天',
  本星期: '本星期',
  全部: '全部',
  机器人说明: 'Plus500Ai的使用者可以同時購買多個量化交易套餐，NO.1機器人每個帳戶只能購買10次。 謹記：購買量化機器人後不要忘記點擊啟動策略，否則將沒有收入。 量化機器人有效時間按購買時間計算，請及時激活量化交易策略，如果量化機器人過期後還未激活,您將失去您的購買量化交易策略套餐的成本!!! 請所有參與Plus500Ai量化交易的用戶仔細閱讀 此條例！ ！',
  会员体验: '會員體驗',
  机器人采购: '機器人採購',
  机器人启动押金: '節點質押金額',
  机器人礼物: '節點禮物',
  机器人订单: '節點訂單',
  一次性总和: '顯示',
  总计: '總計',
  
  被激活: '已質押',
  工作中: '已啟動',
  暂停: '暫停質押',
  结束: '已到期',
  刷新: '刷新',
  来源: '來源',
  价格: '量化套餐價格',
  启动押金: '質押金額',
  剩余时间: '剩餘時間',
  激活时间: '啟動時間',
  号: '號',
  我的资产: '我的資產',
  退出: '登出',
  邀请码: '邀請碼',
  我的钱包余额: '我的錢包餘額',
  我的机器人仓库: '我的機器人列表',
  收益面板: '收益面板',
  去提款: '提款',
  昨天代理返利: '昨天代理佣金',
  累计代理返利: '累計代理佣金',
  今天机器人收益: '今天交易收益',
  累计机器人收益: '累計交易收益',
  累计机器人回扣: '累計交易佣金',
  累计总回报: '累計總收益',
  自动订单机器人数量: '已啟動的量化交易機器人',
  我的付款方式: '我的付款方式',
  个人收入记录: '個人收入',
  团队收入记录: '團隊收入',
  记录详情:'記錄詳情',
  充值记录: '充值記錄',
  我的团队成员: '我的團隊成員',
  活动中心: '規則介紹',
  修改登录密码: '登陸密碼設置',
  交易密码管理: '交易密碼設置',
  机器人: 'AI交易策略',
  机器人性能: 'Ai機器人面板',
  机器人价格: '交易策略價格',
  有效期: '交易有效期限',
  钱包地址: '錢包地址',
  天: '天',
  去购买: '購買',
  买个机器人: '購買量化交易機器人',
  我的钱包余额: '我的賬戶餘額',
  预计日收益: '預計日收益',
  启动保证金: '啟動保證金',
  请输入购买数量: '請輸入購買數量',
  个机器人: '量化交易機器人',
  机器人购买后需要激活才能工作: '購買節點後需要質押對應金額才能開始獲益',
  机器人激活需要从余额中扣除相应的启动押金:
    '節點質押挖礦需要從賬戶餘額中扣除相應的質押金額',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    '暫停節點質押挖礦時可以立刻退回質押的金額',
  购买: '購買',
  点击去了解我们: '量化交易',
  购买机器人: '購買機器人',
  快速入口: '快速入口',
  '日/个人受益': '日/個人受益',
  购买教程: 'AI交易機器人列表',
  激活金额: 'POS費',
  订单数量: '訂單數量',
  我们的合作伙伴: '我們的加密市場合作夥伴',
  提款: '提款',
  提款金额: '提款金額',
  你还没有添加银行卡: '你還沒有添加銀行卡',
  提现说明: '提現說明',
  说明1: '1.您可以一鍵選擇或手動輸入充值金額',
  说明2: '2.推薦使用USDT(TRC20)，轉賬手續費最低',
  说明3: '3.客戶服務時間為週一至週五AM8至PM6',
  说明4: '使用USDT充值的用戶需要上傳正確的轉賬成功截圖和TXID，平台審核通過後會成功到賬',
  说明5: '4.最低充值8 USDT,方便系統快速審核處理到賬',
  说明6: '5.如您對充值有任何問題請及時聯繫在線客服協助處理',
  确认提款: '確認提款 ',
  请输入手机号码:'手機號碼',
  请输入提现金额: '提現金額 ',
  提现费:'提現費',
  提现须知: '請仔細閱讀提款規則',
  TIPS1: '1:每日取款審核時間為周一至週五AM8至PM6。週末可以提款，提款審核時間為周一',
  TIPS2: '2:7X24小時制，隨時取款，最遲24小時到賬，最短5分鐘到賬',
  TIPS3: '3:在線客服服務時間：週一至週五AM8至PM6',
  TIPS4: '4:由於USDT轉賬需要成本，用戶提款時平台收取提款費',
  TIPS5: '5:最低提款金額為10 USDT',
  钱包余额: '錢包餘額',
  输入数量:"輸入數量",
  数量:"數量",
  手机号码:"手機號碼",
  手机号码已存在:"手機號碼已註冊",
  邮箱:"郵箱",
  输入手机号码: '輸入手機號碼',
  请输入登录密码: '登錄密碼',
  登录: '登錄',
  输入账号: '輸入賬號',
  密码: '密碼',
  点击注册: '還沒有帳戶？立即創建一個！',
  忘记密码: '忘記密碼?',
  重新登录: '重新登入',
  密码找回成功: '密碼找回成功',
  找回成功请重新登录: '找回成功 請重新登錄',
  确认: '確認',
  发送验证码: '發送驗證碼',
  再次输入密码: '確認密碼',
  输入验证码: '輸入郵箱CAPTCHA',
  输入手机验证码:"輸入手機驗證碼",
  输入手机号: '輸入手機號',
  输入密码: '登入密碼',
  快速注册: '快速註冊',
  注册: '註冊',
  立即登录: '已有賬號，點擊登陸',
  请输入邮箱: 'Gmail郵箱地址',
  输入用户名: '輸入用戶名',
  请输入邀请码: '請輸入邀請碼',
  返回: '返回',
  密码找回失败: '密碼找回失敗',
  输入的信息有误: '輸入的信息有誤',
	获取验证码:"獲取驗證碼",
	手机号码不能为空:"手機號碼不能為空",
	账号不存在:"賬號不存在",
	发送成功:"發送成功",
	今日收益:"（當前收入）",
	"您好如果您中途终止質押系统将收取5%的質押费用":"您好，如果您中途終止質押，系統將收取5%的質押管理費用。特別提醒：體驗的免費節點暫停質押後將無法再次獲取，也無法獲得任何費用！ ！",
	我同意:"我同意",
	用户账号ID:"賬號ID",
	取消:"取消",
	日收益:"日收益",
	请输入购买数量:"請輸入購買數量",
	数量不能为空:"數量不能為空",
	加载中:"加載中...",
	唯一码:"機器人編碼",
	未激活:"未啟動",
	激活:"啟動策略",
	购买时间:"購買時間",
	"钱包余额不足，激活该机器人失败":"賬戶餘額不足，無法進行節點質押挖礦",
	
	运行时长:"運行時長",
	签约购买:"餘額購買",
	系统赠送:"系統贈送",
	状态:"狀態",
	正常:"正常",
	我的机器人:"我的量化交易機器人",
	一级会员:"一級",
	二级会员:"二級",
	三级会员:"三級",
	人:"",
	充值客服:"充值客服",
	充值:"充值",
	提现:"提現",
	提款密码:"提款密碼",
	设置交易密码:"設置交易密碼",
	登录密码:"登錄密碼",
	请输入交易密码:"輸入交易密碼",
	再次请输入交易密码:"確認交易密碼",
	确认: '確認',
	手机号码不能为空:"手機號碼不能為空",
	两次密码不一致:"兩次密碼不一致",
	请输入验证码:"請輸入驗證碼",
	操作成功:"操作成功",
	"用户名或密码不正确,登录失败":"用戶名或密碼不正確,登錄失敗",
	登录成功:"登錄成功",
	充值说明:"充值說明",
	请先设置支付密码:"請先設置交易密碼",
	复制成功:"複製成功",
	冻结机器人做单本金:"凍結量化資金",
	待审核:"待審核...",
	成功:"成功",
	失败:"失敗",
	审核中:"審核中",
	在线充值:"在線充值",
	描叙:"描述",
	POS节点挖矿LV0:"POS-LV0節點挖礦",
	POS节点挖矿LV1:"POS-LV1節點挖礦",
	POS节点挖矿LV2:"POS-LV2節點挖礦",
	POS节点挖矿LV3:"POS-LV3節點挖礦",
	POS节点挖矿LV4:"POS-LV4節點挖礦",
	POS节点挖矿LV5:"POS-LV5節點挖礦",
	银行卡提现:"銀行卡提現",
	USDT提现:"USDT提現",
	三级代理:"三級代理",
	一级代理:"一級代理",
	二级代理:"二級代理",
	一级:"一級",
	二级:"二級",
	三级:"三級",
	做单:"節點挖礦",
	登录失败:"登錄失敗",
	请勿重复操作:"請勿重複頻繁操作",
	邀请码不正确:"邀請碼不正確",
	团队返佣:"團隊佣金",
	购买机器人返佣:"機器人返傭",
	本金返还:"質押返還",
	佣金收入:"佣金收入",
	时间:"時間",
	机器人做单返还:"質押金額返還",
	涨幅:"漲幅",
	市场:"市場",
	客服:"服務",
	验证码错误:"驗證碼錯誤",
  付款方式:"USDT提現地址",
	我的留言:"我的留言",
  暂无数据: '暫無數據',
  银行卡:'銀行卡',
  修改: '修改',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'請確保您的錢包地址正確，如果您的地址輸入錯誤而導致收不到錢，請及時聯繫在線客服協助',
 连接借记卡:"连接借记卡",
 银行名称:'銀行名稱',
 姓名姓氏: '姓名',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* 重要提示:銀行帳戶資訊必須真實有效才能提款。',
 帮助中心:'幫助中心',
 会员须知:'會員須知',
 '24小时内使用USDT实时取款（推荐）':'1. 24小时内使用USDT实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'我知道了',
 链接点击:'邀請註冊鏈接：',
 确定:'確定',
 建立自己的团队邀请:'建立自己的團隊邀請',
 此账号已冻结:"此賬號已凍結",
 手机号存已存在:"手機號存已存在",
 注册账号已存在:"註冊賬號已存在",
 请确定新密码:"請確定新密碼",
 请再次输入登录密码:"請再次輸入登錄密碼",
 密码长度不能少于6位:"密碼長度不能少於6位",
 加入:'加入',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的节点对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的节点对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"银行卡已被绑定",
 	USDT_TRC20提现:'USDT_TRC20提現',
 	USDT_ERC20提现:'USDT_ERC20提現',
 	银行卡提现:'銀行卡提現',
 	邀请赠送:'邀請獎金',
 	机器人返佣:'機器人返傭',
 	升级:'成功購買',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "交易序號",
   消息详情:"消息詳情",
   个人收益累计:"個人收益累計",
   今日代理收益:"今日代理收益",
   代理累计收益:"代理累計收益",
   截图保存推荐给朋友:"分享您的推薦註冊連結邀請好友加入Plus500Ai平台賺取團隊收入佣金獎勵。 三級團隊成員收益提成8%-3%-2%，團隊佣金依照下級成員每日收入數據，每日00:00後自動結算進入您的帳號餘額",
   复制:"複製",
   充值提示1:"提示1:最低充值5 USDT起,即可充值,否則不到賬",
   充值提示2:"提示2:充值需要鏈上確認，成功充值後，預計一分鐘左右到賬錢包餘額。",
   请输入正确的充值金额:"儲值金額≥8 USDT",
   推荐:"推薦",
   充值金额:"充值金額",
   请上传付款截图:"請上傳付款成功截圖",
   交易号: '交易Hash號',
   充值ID提示:"請黏貼你的Txid交易序列號",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"請確保錢包地址無誤，如因錢包地址填寫錯誤導致無法收款而造成的損失，將由用戶自己承擔。",
	上传凭证:"上傳付款憑證",
	充值确认: "提交",
	不能为空:"不能為空",
  绑定钱包地址:'綁定錢包地址',
	备注:"備註",
	请输入银行卡号:"请输入银行卡号",
	请输入你的名字:"輸入你的名字",
	银行卡号:"銀行帳號",
	添加银行卡:"綁定銀行帳戶",
	请选择银行:"請選擇銀行",
	请输入钱包地址:"請輸入錢包地址",
	钱包地址:"錢包地址",
	"密码错误次数过多,请等待x秒后再试":"密碼錯誤次數過多,請等待600秒後再試",
	"此账号已冻结,请联系客服":"此賬號已凍結,請聯繫客服",
	手机号格式错误:"手機號格式錯誤",
	"取款须知":"取款須知",
	"须知1":"1. 24小時內使用USDT-TRC20實時取款（推薦）",
	"须知2":"2. 週六週日提款",
	"须知3":"*用戶週六週日可以提款",
	"须知4":"*週末取款將於週一10AM-8PM到賬",
	我知道了:"我知道了",
	"提现0":"1USDT=1USD ERC20",
	"提现1":"提示1：最低单笔提现100USDT/天，单笔交易手续费5USDT/次",
	"提现2":"TRC20提示2：每次提款最低10USDT，每次交易手续费1USDT。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"已綁定",
	去设置:"去設置",
	注册成功:"註冊成功",
	汇率:"匯率",
	请输入正确的提现金额:"請輸入正確的提現金額",
	提款记录:"提款記錄",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:綁定的錢包地址必須跟付款錢包一致，否則不會到賬。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功後預計在一分鐘左右到賬，請檢查錢包餘額。",
	请先绑定钱包地址:"請先綁定錢包地址",
	输入邮箱:"輸入郵箱",
	邮箱已存在:"郵箱已存在",
	注册邮箱已存在:"註冊郵箱已存在",
	指定用户不存在:"指定用戶不存在",
	今日涨幅:"漲跌幅",
	恭喜新用户:"恭喜新用戶",
	快捷充币:"存款",
	快速买USDT:"USDT充值",
	在线支付:"在線支付",
	自动到账:"自動到賬",
	线下支付:"線下支付",
	联系客服获取验证码:"聯繫客服獲取驗證碼",
	获取:"獲取",
	退出成功:"退出成功",
	不能低于最小充值:"不能低於最小充值",
	不能低于最小提现金额:"不能低於最小提現金額",
	最小提现:"最小提現",
	设置成功:"設置成功",
	官方充币:"系統充值",
	银行卡充值:"銀行卡充值",
	等待审核:"等待審核",
	可提现金额不足:"可提現金額不足",
	未经授权:"未經授權",
	交易密码不正确:"交易密碼不正確",
	提现次数不足:"提現次數不足",
	官网:"官网",
	账户usdt不足:"賬戶USDT餘額不足",
	激活成功: "質押成功",
	操作成功: "操作成功",
	修改成功:"修改成功",
	注册说明:"手機OTP和Email CAPTCHA 只需選擇性1種方式驗證即可成功註冊",
	弹窗内容:
	"歡迎來到Plus500Ai 量化交易平台，每位用戶可以使用註冊獎金1 USDT購買NO.0量化機器人交易策略套餐，有效期1天，免費賺取0.5 USDT。 其他量化交易策略套餐都需要對應費用才能使用。 用戶購買機器人後需要啟動量化交易策略才能自動開始運行，每個交易策略對應不同的有效期限。 如果用戶購買對應機器人點後沒有點擊啟動策略 ，您將不會獲得任何交易收益。",
	标题1:'1.Plus500平台介紹',
	标题2:'2.Ai量化交易的優點',
	标题3:'3.為什麼選擇Plus500Ai?',
	标题4:'4.如何加入Plus500Ai',
	标题5:'5.Plus500Ai市場前景',
	标题6:'6.Plus500Ai收益模式',
	标题7:'7.充值與提現條例',
	标题8:"大逃殺遊戲玩法",
	内容8:"Plu500Ai平台的大逃殺遊戲中有8個房間，玩家在8個房間中任意選擇一個房間投放金幣，在系統倒數結束後，遊戲系統會出現一個殺手，殺手隨機進入一個房間消滅掉房間的人， 其他玩家便可以瓜分這個房間的金幣，獲得獲利。 大逃殺遊戲的玩法比較短，一局只有幾分鐘，玩家能夠在閒暇時間玩。 瓜分金額 = 本期殺手殺掉的房間總額。 實際分割金額 = 分割金額 * 0.9（扣除銷毀的比例為0.1）。 分割比例 = 實際分割金額 / 其他房間總投注金額。 中獎金額 = 瓜分比例* 實際投注金額。 1金幣=1USDT, 最低需要10 USDT才能參與大逃殺遊戲。",
	内容1:'Plus500交易平臺由Plus500SEY Ltd提供。因此，Plus500SEY Ltd是本網站所述或提供的金融產品的發行人和銷售商。Plus500SEY Ltd是一家總部位於塞舌耳的公司（公司 編號 8426415-1）其辦事處位於維多利亞。本公司經塞舌耳金融服務管理局（FSA）授權和監管，許可證號為SD039，提供各種基礎產品的差價合約（CFD）。本公司是一家快速發展的CFD供應商，目前提供超過2800種金融工具的投資組合。Plus500SEY Ltd是Plus500 Ltd的子公司，Plus500 Ltd已在倫敦證券交易所的主要市場上市，總部設在海法。',
	内容2:'AI全自動量化交易的優點就是用理性的工具按模型設定去操作交易，機器人沒有情緒變化，能克服人性、人工在操作過程中的貪婪與恐懼及其他很多不足和弱點。 人工操作往往會受到大盤漲跌及其他各種各樣的因素影響，不能長期按照最好的獲利模式去操作。',
	内容3:'Plus500Ai透過將核心產品擴展到新市場和現有市場，推出新的交易和金融產品，並深化與客戶的接觸，Plus500作為一家多資產金融科技集團的地位逐漸加強。保护您的资产，使用行业领先的安全技术保护您的加密密钥。Plus500Ai还提供全局托管，这意味着我们受到监管和审计，最高可达 2.5 亿美元的保险。部署您的资金，目标不仅仅是持有资产，而是让它们发挥作用。Plus500Ai允许您以多种方式部署您的资金，从 Ai量化交易 到经典的主要服务，取决于您的风险承受能力。在线平台，无需雇用额外的区块链工程师，即可为您自己的用户提供更多服务。',
	内容4:'Plus500Ai成立以來，只有一個官網WWW.PLUS500AI.COM。 所有的用戶需登錄官網下載APP,如其它網址均不是本公司的。 註冊需提交用戶的手機號碼以及郵箱和推薦者的邀請碼即可快速註冊。 所有與客戶的溝通均以書面形式進行，可以是透過電子郵件或線上聊天。若要透過電子郵件聯絡Plus500，請填寫 聯絡我們 頁面上的表格（申請表）。提交申請表後，Plus500會透過電子郵件直接發送回復到您在申請表中指定的郵箱地址。如有疑問請聯絡客服。',
	内容5:'Plus500Ai跨產品、設備和國家/地區運營，為客戶提供可靠和直觀的金融交易機會,我們一貫追求卓越的產品和團隊。憑藉先進的專有技術和以客戶為中心的方法，我們的用戶可以獲得一流的交易服務體驗。',
	内容6:'Plus500Ai由高級數據分析師團隊提供專業的加密貨幣市場量化交易策略，參與投資購買Ai量化交易機器人的用戶可以依靠和機器人配套的交易策略獲得量化交易利潤。 Ai 量化交易機器人根據Plus500Ai的市場交易策略循環分析最優交易時間，透過低階買進高價賣出的模式獲得量化交易利潤。 Plus500Ai每日為投資的用戶提供最優量化交易策略，平台抽取用戶量化收益利潤的5%作為平台營運資金。',
	内容7:'Plus500Ai目前支持USDT充值与提现，如后续开通其它通道官网会有公告。最低充值和提款均为10 USDT,充值与提现最快5分钟，最慢24小时内。用户提款时间没有限制，您可以随时提款。提款在正常工作日5分钟-24小时内到达。周末提款统一安排至周一处理。',
	BSC提现:"USDT_BSC提現",
	社区Ads收益:"社區Ads收益",
	Ads收益区间:"Ads收益1-1000$",
	申请Ads费用:"申請Ads費用",
	Ads促销地址:"Ads促銷地址",
	填写Ads促销地址:"填寫Ads促銷地址",
	申请Ads费用须知:"申請Ads費用須知",
	须知A:"1. 用戶錄製促銷視頻上傳發佈到自己的YouTube，Facebook,頻道或其他社區進行Ads促銷，申請$1-$1000獎金，提交申請3天后平台將審核您的Ads視頻素材，並根據視頻促銷效果將Ads獎金打入您的賬戶餘額，無任何促銷效果的視頻無法通過審核",
	须知B:"2. 同一頻道同一Ads促銷廣告視頻只能申請一次獎金，每周可發布一次新的促銷視頻素材，不要惡意重複發送請求。如果您惡意多次在平台上提交非本人製作的Ads請求或者審核失敗的Ads素材，系統檢測後您的帳戶將被禁止使用",
	须知C:"3. 用戶錄製的Ads視頻素材需要有基本的平台介紹和平台功能解說，視頻時間不短於5分鐘，您的頻道訂閱者數量越多，視頻的促銷效果越好，可以申請的Ads費用越高。具體Ads費用參考如下，以YOUTUBE為例：",
	须知D: "訂閱者0-2K:申請金額1-20$",
	须知D1:"訂閱者3K-10K:申請金額21-40$",
	须知D2:"訂閱者11K-30K:申請金額41-100$",
	须知D3:"訂閱者31K-50K:申請金額101-300$",
	须知D4:"訂閱者51K-100K:申請金額301-1000$",
	须知D5:"4. 系統將將於每週日對Ads獎金申請進行審核處理，符合資格的使用者將收到系統派送的獎金",
    请求审核:"請求審核",
	申请金额:"申請金額",
	URL促销地址:"視頻促銷地址",
	提交时间:"提交時間",
	需要更新的银行卡不存在:"需要更新的銀行卡不存在",
	银行卡提现:"銀行卡提現",
	预计收入:"預計收入",
	总投资:"總投資",
	购买价:"購買價",
	邀请:"邀請",
	成员:"會員",
	积分等级折扣规则介绍3:"此折扣金額僅有效期至2023年12月31日，2024年1月1日後將有新的調整。",
	LV1佣金:"LEVEL1佣金",
	LV2佣金:"LEVEL2佣金",
	LV3佣金:"LEVEL3佣金",
	合格条件:"每人最低充值8$",
	随时可退:"隨時可退",
	登录密码错误:"登入密碼錯誤",
	充值奖金:"儲值USDT≥500USDT，獎金3%",
	充值奖金1:"儲值IDR≥50USDT，獎金2%",
	充值奖金2:"儲值THB≥50USDT，獎金5%",
	不能重复发送短信:"10 分钟内不能重复发送短信。 建议您使用EMAIL CAPTCHA直接注册。",
	限购说明1:"您好，NO.0 量化機器人交易策略套餐每個用戶只能購買一次",
	活动已过期:"活動已過期",
	修改钱包地址提示:"USDT提現地址綁定後無法修改，如需協助，請聯絡客服",
	修改银行提示:"提款銀行帳戶綁定後無法修改，如需協助，請聯絡客服",
	注册推送消息1:"歡迎來到INGSAI，新用戶啟動POS 挖礦節點後，每5分鐘獲得一次POS 挖礦收入。 團隊佣金收入每日00.00左右自動進入帳戶餘額",
	限购说明:"您好，該類型量化機器人套餐最大購買次數為10次，建議您購買其他類型量化交易套餐",
	注册协议:"按‘創建帳戶’，即表示您確認您已年滿18周歲。您已知悉Plus500AI可能會使用您的電子郵件地址或其他個人資料以電子方式與您聯繫，提供其產品/服務的相關資訊。我們也可能會通知您市場事件，幫助您最大限度地利用您的交易經驗。您可以在平臺上的'通知設置'和‘隱私設置’選項卡中管理和修改通知首選項。您可以隨時取消訂閱這些資訊。更多資訊，請查閱我們的‘隐私协议’",
    公司说明1:"Plus500AI是Plus500 Ltd 的注冊商標。 Plus500 Ltd透過以下子公司進行運營:",
    公司说明2:"Plus500SEY Ltd由塞舌耳金融服務管理局授權和監管（許可證 號 SD039）。",
    公司说明3:"Plus500SEY Ltd是本網站上所描述或可用金融產品的發行人和賣方。",
    公司说明4:"Plus500UK Ltd 由金融行為監管局授權和管理。金融行為監管局 註冊號碼：509909. 加密貨幣CFD未向散戶用戶提供。",
    公司说明5:"Plus500CY Ltd受塞浦路斯證券及交易委員會授權和監管（授權編碼：250/14）。加密貨幣CFD未向英國散戶提供。",
    公司说明6:"Plus500AU Pty Ltd 持有以下牌照：即ASIC頒發的 AFSL（編號 417727）、紐西蘭FMA 頒發的FSP（編號486026），以及南非FSCA 頒發的授權金融服務提供者（編號47546）。",
    公司说明7:"Plus500EE AS 受愛沙尼亞金融監管局授權及監管（牌照號碼No. 4.1-1/18）。",
    公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) 持有新加坡金融管理局頒發的資本市場服務執照，允許進行資本市場產品交易（執照號碼 CMS100648）。",
    公司说明9:"Plus500AE Ltd由迪拜金融服務管理局（F005651）授權和監管。",
	加入我们的社群:"加入我們的社群",
	PLUS500集团:"PLUS500 集團",
	受监管的操作:"受監管的操作",
	健全的全球监管框架:"健全的全球監理框架",
	治理和社会责任:"治理和社會責任",
	底部说明:"Plus500 始終致力於在其業務的各個方面以負責任和可持續的方式運營，並相信這種方法既是其職責，也是有效管理的重要組成部分。 Plus500 致力於一系列 ESG 舉措，為我們的員工、客戶、當地社區和慈善機構以及我們的股東創造有形價值。",
	投资者联系方式:"投資者聯絡方式",
	投资者关系主管:"投資人關係主管",
	AI智慧型量化交易:"AI智慧量化交易",
	订单执行快速可靠:"訂單執行快速可靠",
	全天候自动分析行情:"全天候自動分析行情",
	交易受监管低风险:"交易受監管低風險",
	稳定策略高效收益:"穩定策略高效能效益",
	取款快速且安全:"取款快速且安全",
	游戏说明:"最低餘額為10 USDT即可進入遊戲並賺錢",
}
