<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('语言切换') }}</div>
      <div>
        <!-- <img class="img" src="../../assets/img/添加.png" alt="" /> -->
      </div>
    </div>
    <div class="contnet">
      <div class="card">
        <div class="collapseBox">
		  <div @click="chngelang('en')" class="help" >
			 <img class="img" src="../../static/images/country/44.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
			  <span style="height: 30px; margin-left:10px;">English</span>
		  </div>
		  <div @click="chngelang('fr')" class="help" >
			  <img class="img" src="../../static/images/country/33.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
		  			  <span style="height: 30px; margin-left:10px;">Français</span>
		  </div>
		  <div @click="chngelang('de')" class="help" >
			  <img class="img" src="../../static/images/country/49.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
		  			  <span style="height: 30px; margin-left:10px;">Deutsch</span>
		  </div>
		  <div @click="chngelang('ru')" class="help" >
			  <img class="img" src="../../static/images/country/7.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
		  			  <span style="height: 30px; margin-left:10px;">ру́сский язы́к</span>
		  </div>
		  <div @click="chngelang('uz')" class="help" >
		  			  <img class="img" src="../../static/images/country/998.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
		  			  <span style="height: 30px; margin-left:10px;">Oʻzbek tili</span>
		  </div>
		  <div @click="chngelang('az')" class="help" >
		  			  <img class="img" src="../../static/images/country/994.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
		  			  <span style="height: 30px; margin-left:10px;">Azerbaijani</span>
		  </div>
		  <div @click="chngelang('kr')" class="help" >
			  <img class="img" src="../../static/images/country/82.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
		  			  <span style="height: 30px; margin-left:10px;">한국어</span>
		  </div>
		  <div @click="chngelang('th')" class="help" >
			  <img class="img" src="../../static/images/country/66.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
		  			  <span style="height: 30px; margin-left:10px;">ภาษาไทย</span>
		  </div>
		  <div @click="chngelang('tr')" class="help" >
			  <img class="img" src="../../static/images/country/90.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
		  			  <span style="height: 30px; margin-left:10px;">Türkçe</span>
		</div>
		  <div @click="chngelang('hi')" class="help" >
			  <img class="img" src="../../static/images/country/91.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
					  <span style="height: 30px; margin-left:10px;">हिन्दी或हिंदी</span>
		</div>
		  <div @click="chngelang('es')" class="help" >
			  <img class="img" src="../../static/images/country/34.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
					  <span style="height: 30px; margin-left:10px;">Español</span>
		</div>
		  <div @click="chngelang('bn')" class="help" >
			  <img class="img" src="../../static/images/country/880.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
					  <span style="height: 30px; margin-left:10px;">বাঙালী</span>
		</div>
		  <div @click="chngelang('pt')" class="help" >
			  <img class="img" src="../../static/images/country/351.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
					  <span style="height: 30px; margin-left:10px;">Português</span>
		</div>
		  <div @click="chngelang('jp')" class="help" >
			  <img class="img" src="../../static/images/country/81.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
					  <span style="height: 30px; margin-left:10px;">日本語</span>
		</div>
		  <div @click="chngelang('zhft')" class="help" >
			  <img class="img" src="../../static/images/country/886.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
					  <span style="height: 30px; margin-left:10px;">中文繁體</span>			  
		  </div>
		  <div @click="chngelang('id')" class="help" >
			  <img class="img" src="../../static/images/country/62.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
		  			  <span style="height: 30px; margin-left:10px;">Bahasa Indonesia</span>
		  </div>
		  <div @click="chngelang('ar')" class="help" >
			  <img class="img" src="../../static/images/country/966.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
		  			  <span style="height: 30px; margin-left:10px;">لغة عربية</span>
		  </div>
		  <div @click="chngelang('zh')" class="help" >
			  <img class="img" src="../../static/images/country/86.png" alt="" style="vertical-align:middle; height: 20px; width:26.6px;" />
		  			  <span style="height: 30px; margin-left:10px;">中文简体</span>
		  </div>
        </div>
      </div>
    </div>
    <ROBOT />
  </div>
</template>

<script>

import ROBOT from '@/components/robot'
// import HBBTN from '@/components/hb-btn'

export default {
  name: 'welcome',
  components: {
    ROBOT
  },
  data() {
    return {
      num: '',
      radio: 1,
      list: [],
      token: '',
      activeNames: 0
    }
  },
  created() {
  
  },
  methods: {
	  chngelang(lang){
		this.$i18n.locale = lang;
		console.log(lang);
		this.$router.back()
	  },
    // confirmEvent() {
    //   console.log('ceshi')
    // },
    getBack() {
      this.$router.back()
    },
    btnEvent() {
      console.log('dsfs')
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
	  margin-top: 10px; 
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: bold;
      color: #2E86FE;
    }
  }
  .contnet {
    margin-top: 42px;
    width: 100%;
    border-radius: 20px;
    // background-color: #2a2c2e;
  }
  .card {
  }
  .footerBtn {
    margin-top: 50px;
  }
}
::v-deep .van-cell {
  //   background-color: #2a2c2e;
  background: #2a2c2e !important;
  border-radius: 20px;

  //   border-radius: 20px 20px 0 0;
}
::v-deep .van-collapse-item__content {
  background: #2a2c2e !important;
  border-radius: 20px;
  //   border-radius: 0 0 20px 20px;
}
::v-deep .van-collapse-item {
  border-radius: 20px;
  //   height: 90px;
  background: #2a2c2e !important;
}
.collapseBox {
  margin-bottom: 20px;
}
.help {
  margin-bottom: 30px !important;
  border: 1px solid gray !important;
  width: 95%;
  line-height: 50px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 20px;
  font-size: 25px;
}
::v-deep .van-collapse-item--border {
  border: none !important;
}
</style>
