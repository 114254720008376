export default {
  tabbar: {
    首页: "Bosh sahifa",
    市场: "Bozor",
    任务: "Vazifa",
    团队: "Jamoa",
    我的: "Mening"
  },
  navbar: {
    充值: "Omonat",
    提现: "Pul olish",
    帮助: "FAQ",
    分享: "Yo'naltiruvchi",
	推广: "Targ‘ibot",
    活动: "Mukofot",
    游戏: "O'yin",
  },	 
  payment: {
    付款方式详情: "To'lov usuli tafsilotlari",
    银行卡: "Bank kartasi",
    钱包地址: "Olib tashlash usuli",
    交易密码: "Tranzaksiya paroli",
    保存: "Saqlash"
  },
  market: {
    市场: "Bozor",
    名称: "Ism",
    价格: "Narxi",
  },
 
  task: {
    任务: "任务",
	我的余额: "我的余额",
    增加订单数量: "增加订单数量",
    今日剩余订单: "今日剩余订单",
    频率: "频率",
    手动交易: "手动交易",
    预期收益: "预期收益",
    需要启动存款: "需要启动存款",
    任务说明: "任务说明",
    每天可以完成10个任务: "每天可以完成10个任务",
    联系VIP助手增加任务数量: "联系VIP助手增加任务数量",
    每个用户的任务总数为300次: "每个用户的任务总数为300次",
    购买机器人无需人工操作即可自动交易: "购买机器人无需人工操作即可自动交易",
    我知道了: "Men ko'ryapman"
  },
  邀请好友注册领取佣金:"Do'stlaringizni ro'yxatdan o'tishga va komissiya olishga taklif qiling",
  领取:"Qabul qilish",
  未满足条件:"Shart bajarilmadi",
  总收入:"Jami daromad",
  投资建议:"※Ko'proq daromad olish uchun murakkab foizli investitsiya rejasini amalga oshiring",
  语言切换: "Til",
  POS区块节点挖矿: "Ai miqdoriy savdo tafsilotlari",
  节点类型: "Robot\nTuri",
  加入价格: "Sotib olish\nNarxi",
  质押资金: "POS\nmablag'lar",
  质押周期: "Davr\n(kunlar)",
  每日收入: "Kundalik\nDaromad",
  累计积分: "Yig'ilgan\nBallar",
  代理等级: "Agent\nDaraja",
  下级折扣比: "Bo'ysunuvchi\nChegirma",
  代理折扣比: "Agent\nChegirma",
  下属等级: "Bo'ysunuvchi\nDaraja",
  代理佣金比率: "Agent\nKomissiya\nBaho",
  下属每日收入: "Bo'ysunuvchi\nKundalik\nFoyda",
  代理佣金: "Agent\nKomissiya",
  引用对象: "Ma'lumotnoma\nob'ekt",
  积分等级折扣规则: "Ballar darajasidagi chegirma qoidalari",
  积分等级折扣规则介绍: "Ai miqdoriy savdosida muvaffaqiyatli ishtirok etgan foydalanuvchilar tegishli hisob ballariga ega bo'ladilar va ularning agenti yuqori foydalanuvchilari ham tegishli ballarni oshirishlari mumkin (to'g'ridan-to'g'ri bo'ysunuvchilarning 15% ga tegishli). Jami hisob ballari tegishli shartlarga javob bersa, agent hisobining darajasi yaxshilanadi. , Turli darajadagi agent hisoblari narx chegirmalarining turli nisbatlarini olishlari mumkin. Tegishli chegirmalarning tafsilotlari quyidagicha:",
  积分等级折扣规则介绍2: "Turli xil munosabatlarga ega (to'g'ridan-to'g'ri) foydalanuvchilar Ai miqdoriy savdolarida bo'ysunuvchilarning ishtiroki nisbati asosida chegirmalar va agent darajasidagi chegirmalarga ega bo'ladilar. Boshqa yuqori lavozimli rahbarlar ham o'z agentlariga mutanosib ravishda chegirma darajalariga ega bo'lishadi. Ai miqdoriy savdolarida qatnashish uchun chegirmalar: Tegishli agent darajalariga ega foydalanuvchilar Ai miqdoriy savdolarida ishtirok etishda tegishli agent darajasidagi imtiyozli huquqlarga ega bo'ladilar.",
  团队: "Jamoa",
  代理佣金制度: "Agentlik komissiyasi tizimi",
  代理佣金说明详情: "A tomonidan olingan LV1 agentlarining nisbati: 8%, A tomonidan olingan LV2 agentlarining nisbati: 3%, A tomonidan olingan LV3 agentlarining nisbati: 2%. Misol: B, C va D ning kunlik foydasi: 100 AQSh dollari va A tomonidan olingan LV1 agenti mukofoti: 8%*100=8 USD. A tomonidan olingan LV2 agenti mukofoti: 3%*100=3 USD. A tomonidan olingan LV3 agent mukofoti: 2%*100=2 USD. Jami komissiya daromadi: 8+3+2=13 USD.",
  代理结构图示: "Proksi tuzilma diagrammasi",
  代理结构图示说明: "A sizni ifodalaydi, A B ni taklif qiladi (A bo'ysunuvchisi B), B C ni taklif qiladi (B bo'ysunuvchisi C) va C D ni taklif qiladi (C bo'ysunuvchisi D). A bir nechta bo'ysunuvchilarga ega bo'lishi mumkin (B1, B2, B3, B4, B5, B6 va boshqalar), B (B1, B2, B3) ham bir nechta bo'ysunuvchilarga (C1, c2, c3, c4, c5, c6 va boshqalar) ega bo'lishi mumkin. ) C... D...",
  高级代理佣金说明: "Senior Agent Commission Instructions",
  高级代理佣金说明详情: "A sizni ifodalaydi, A B ni taklif qiladi (A bo'ysunuvchisi B), B C ni taklif qiladi (Bs bo'ysunuvchisi C) va C D ni taklif qiladi (C bo'ysunuvchisi D). Yuqori agent o'z hisobiga va bo'ysunuvchi hisoblarga jalb qilingan tugunni qazib olishning umumiy miqdori nisbatiga ko'ra turli agentlik komissiyasi daromadlarini (%) oladi. A agentlik mukofotlarining tegishli nisbatini olish uchun bo'ysunuvchi a'zolarning kunlik daromadlarini taqqoslaydi. Ishtirokchi tugunlar ishlayotgan bo'lishi kerak. Agentlik daromadi barcha moliyaviy xarajatlar uchun Plus500ai tomonidan taqdim etiladi va oddiy bo'ysunuvchi a'zolarning kunlik daromadiga ta'sir qilmaydi.",
  高级代理佣金说明详情2: "Misol: Katta agentlarning umumiy qazib olish miqdori (A) quyi a'zolarning umumiy qazib olish miqdoridan >30% (B/C/D) (Misol: A>B/C/D ning 30%)",
  积分: "Ballar",
  规则: "Qoida",
  会员级别: "A'zolik darajasi",
  经验: "Jami ballar",
  代理信息: "Aʼzolik maʼlumotlari",
  更多: "Ko'proq",
  国码:"Mamlakat kodi",
  团队奖励: "Jamoa komissiyasi",
  昨天: "Kecha",
  本星期: "Ushbu hafta",
  全部: "Barcha",
  机器人说明: "Plus500Ai foydalanuvchilari bir vaqtning o'zida bir nechta miqdoriy savdo paketlarini sotib olishlari mumkin va NO.1 roboti har bir hisob uchun faqat 10 marta sotib olishi mumkin. Esda tuting: Miqdoriy robotni sotib olgandan so'ng strategiyani faollashtirish uchun bosishni unutmang, aks holda daromad bo'lmaydi. Miqdoriy robotning amal qilish muddati sotib olish vaqtiga qarab hisoblanadi. Iltimos, miqdoriy savdo strategiyasini vaqtida faollashtiring. Agar miqdoriy robot muddati tugagandan keyin faollashtirilmagan bo'lsa, siz miqdoriy savdo strategiyasi paketini sotib olish narxini yo'qotasiz!!! Plus500Ai miqdoriy savdosida ishtirok etuvchi barcha foydalanuvchilar diqqat bilan o'qing. Ushbu tartibga solish! !",
  会员体验: "A'zolik tajribasi",
  机器人采购: "Robot sotib olish",
  机器人启动押金: "Tugun ulushi",
  机器人礼物: "Robot sovg'asi",
  机器人订单: "Robot buyurtmasi",
  一次性总和: "Displey",
  总计: "Jami",
  
  被激活: "Allaqachon POS",
  工作中: "FAOL QILGAN",
  暂停: "PAUSE POS",
  结束: "MUDDATI O'TGAN",
  刷新: "Yangilash",
  来源: "Manba",
  价格: "Robot paketi narxi",
  启动押金: "POS miqdori",
  剩余时间: "Vaqt qoldi",
  激活时间: "Vaqtni yoqing",
  号: "NO.",
  我的资产: "Mening aktivlarim",
  退出: "Hisobdan chiqing",
  邀请码: "Yo'naltirish kodi",
  我的钱包余额: "Hamyon balansi",
  我的机器人仓库: "Mening robotlar ro'yxati",
  收益面板: "Daromad paneli",
  去提款: "Pul olish",
  昨天代理返利: "Kecha agentlik komissiyasi",
  累计代理返利: "Kumulyativ agentlik komissiyasi",
  今天机器人收益: "Bugungi savdo foydasi",
  累计机器人收益: "Savdodan jami daromad",
  累计机器人回扣: "Yig'ilgan savdo komissiyasi",
  累计总回报: "Jami jami daromad",
  自动订单机器人数量: "Faollashtirilgan miqdoriy savdo roboti",
  我的付款方式: "Mening toʻlov usulim",
  个人收入记录: "Shaxsiy daromad",
  团队收入记录: "Jamoa daromadi",
  记录详情:"Tafsilotlarni yozib oling",
  充值记录: "Depozit yozuvlari",
  我的团队成员: "Mening jamoam a'zolari",
  活动中心: "Qoidalar bilan tanishtirish",
  修改登录密码: "Kirish parolini sozlash",
  交易密码管理: "Tranzaksiya parolini sozlash",
  机器人: "Ai savdo strategiyasi",
  机器人性能: "Robot paneli",
  机器人价格: "Savdo strategiyasi narxi",
  有效期: "Savdo amal qilish davri",
  钱包地址: "Olib tashlash usuli",
  天: "Kun",
  去购买: "Boshlash",
  买个机器人: "Quant savdo robotini sotib oling",
  我的钱包余额: "Mening hisobim balansi",
  预计日收益: "Hisoblangan kunlik daromad",
  启动保证金: "Boshlang'ich depozit",
  请输入购买数量: "Xarid miqdorini kiriting",
  个机器人: "Quant savdo roboti",
  机器人购买后需要激活才能工作: "Tugunni sotib olgandan so'ng, foyda olishni boshlash uchun tegishli POS miqdori talab qilinadi",
  机器人激活需要从余额中扣除相应的启动押金:
    "Tugun POS konida hisob balansidan tegishli garov miqdorini olib tashlash kerak",
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    "Tugun POS qazib olish to'xtatilganda darhol qaytarilishi mumkin bo'lgan POS miqdori",
  购买: "XARID OL",
  点击去了解我们: "Miqdoriy savdo",
  购买机器人: "Robotlarni sotib oling",
  快速入口: "Tez kirish",
  "日/个人受益": "Kunlik/shaxsiy foyda",
  购买教程: "AI savdo robotlari ro'yxati",
  激活金额: "POS FEE",
  订单数量: "Buyurtma miqdori",
  我们的合作伙伴: "Bizning kripto bozori hamkorlarimiz",
  提款: "Pulni yechib olish",
  提款金额: "Pulni yechib olish miqdori",
  你还没有添加银行卡: "Siz bank kartasini qo'shmagansiz",
  提现说明: "Olib tashlash bo'yicha ko'rsatmalar",
  说明1: "1.Siz bir marta bosish bilan tanlashingiz yoki to'ldirish miqdorini qo'lda kiritishingiz mumkin",
  说明2: "2.USDT (TRC20) dan foydalanish tavsiya etiladi, transfer narxi eng past",
  说明3: "3.Mijozlarga xizmat ko'rsatish soatlari dushanbadan jumagacha AM8 dan PM6 gacha Markaziy Amerika vaqti",
  说明4: "To'ldirish uchun USDT dan foydalanadigan foydalanuvchilar muvaffaqiyatli o'tkazma va TXIDning to'g'ri skrinshotini yuklashlari kerak va platforma tekshiruvdan o'tgandan so'ng hisob muvaffaqiyatli qabul qilinadi.",
  说明5: "4. Minimal to'ldirish 8 USDTni tashkil etadi, bu tizim hisobni tezda ko'rib chiqish va qayta ishlash uchun qulaydir",
  说明6: "5. Zaryadlash haqida savollaringiz bo'lsa, yordam uchun o'z vaqtida onlayn mijozlarga xizmat ko'rsatish bilan bog'laning",
  确认提款: "Naqd pul olishni tasdiqlang",
  请输入手机号码:"Telefon raqami",
  请输入提现金额: "Pulni yechib olish miqdori",
  提现费:"Pul yechib olish to‘lovi",
  提现须知: "Iltimos, pul o'tkazish qoidalarini diqqat bilan o'qing",
  TIPS1: "1: Pulni yechib olishning kunlik ko'rib chiqish vaqti dushanbadan jumagacha AM8 dan PM6 gacha. Pul mablag'larini olish dam olish kunlarida amalga oshirilishi mumkin, va yechib olish ko'rib chiqish vaqti dushanba",
  TIPS2: "2:7X24 soat tizimi, istalgan vaqtda pul yechib oling, hisobingizga eng kech 24 soat ichida yetib boring va eng qisqa vaqt ichida 5 daqiqa ichida hisobingizga yetib boring.",
  TIPS3: "3: Onlayn mijozlarga xizmat ko'rsatish vaqti: dushanbadan jumagacha AM8 dan PM6 gacha",
  TIPS4: "4: USDT o'tkazmasining narxi tufayli, foydalanuvchi pul yechib olganida platforma yechib olish uchun komissiya oladi.",
  TIPS5: "5: Minimal yechib olish miqdori 10 USDT",
  钱包余额: "Hamyon balansi",
  输入数量:"Miqdorni kiriting",
  数量:"Miqdori",
  手机号码:"Telefon raqami",
  手机号码已存在:"Mobil telefon raqami ro'yxatga olingan",
  邮箱:"EMail",
  邮箱不能为空:"Elektron pochta bo'sh bo'lishi mumkin emas",
  输入手机号码: "Mobil raqamni kiriting",
  请输入登录密码: "Kirish paroli",
  登录: "Tizimga kiring",
  输入账号: "Hisob raqamini kiriting",
  密码: "Parol",
  点击注册: "Hisobingiz yo‘qmi? Hoziroq hisob yarating!",
  忘记密码: "Parolni unuting",
  重新登录: "Qayta kirish",
  密码找回成功: "Parol muvaffaqiyatli olindi",
  找回成功请重新登录: "Muvaffaqiyatli yuklab olindi, qayta kiring",
  确认: "Tasdiqlang",
  发送验证码: "Tasdiqlash kodini yuboring",
  再次输入密码: "Parolni tasdiqlang",
  输入验证码: "Tasdiqlash kodini kiriting",
  输入手机验证码:"Mobil OTPni kiriting",
  输入手机号: "Telefon raqamini kiriting",
  输入密码: "Kirish paroli",
  快速注册: "Tez ro'yxatdan o'tish",
  注册: "Roʻyxatdan oʻtish",
  立即登录: "Allaqachon hisobingiz bor, tizimga kirish uchun bosing",
  请输入邮箱: "Gmail elektron pochta manzilingizni kiriting",
  输入用户名: "Foydalanuvchi nomingizni kiriting",
  请输入邀请码: "Yo'naltiruvchi kodni kiriting",
  返回: "Qaytish",
  密码找回失败: "Parolni tiklash amalga oshmadi",
  输入的信息有误: "Kiritilgan ma'lumotlar noto'g'ri",
	获取验证码:"CAPTCHA",
	手机号码不能为空:"Telefon raqami bo'sh bo'lishi mumkin emas",
	账号不存在:"Hisob mavjud emas",
	发送成功:"Muvaffaqiyatli yuborildi",
	今日收益:"(Joriy daromad)",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"Salom, agar siz POS-ni o'rtada tugatsangiz, tizim 5% POS to'lovini oladi. Maxsus eslatma: Tajribaning bepul tugunlari to'xtatilgandan so'ng, siz uni qayta qo'lga kirita olmaysiz va hech qanday to'lov ololmaydi! !",
	我同意:"Roziman",
	用户账号ID:"Hisob ID",
	取消:"Bekor qilish",
	日收益:"Kundalik daromad",
	请输入购买数量:"Xarid miqdorini kiriting",
	数量不能为空:"Miqdor bo'sh bo'lishi mumkin emas",
	加载中:"Yuklanmoqda...",
	唯一码:"Robot kodlash",
	未激活:"NOFAOL",
	激活:"ROBOTni yoqing",
	购买时间:"Sotib olish vaqti",
	"钱包余额不足，激活该机器人失败":"Hisob balansi yetarli emas, tugun POS konini amalga oshirish mumkin emas",

	运行时长:"Ish vaqti",
	签约购买:"Balans sotib olish",
	系统赠送:"Tizim sovg'asi",
	状态:"Davlat",
	正常:"Normal",
	我的机器人:"Mening Quant savdo robotim",
	一级会员:"1-daraja",
	二级会员:"2-daraja",
	三级会员:"3-daraja",
	人:"",
	充值客服:"Depozit mijozlarga xizmat ko'rsatish",
	充值:"Omonat",
	提现:"Olib tashlash",
	提款密码:"Olib tashlash paroli",
	设置交易密码:"Tranzaksiya parolini o'rnating",
	登录密码:"Kirish paroli",
	请输入交易密码:"Tranzaksiya parolini kiriting",
	再次请输入交易密码:"Tranzaksiya parolini tasdiqlang",
	确认: "Tasdiqlang",
	手机号码不能为空:"Telefon raqami bo'sh bo'lishi mumkin emas",
	两次密码不一致:"Ikki parol mos kelmaydi",
	请输入验证码:"E-pochta manzilini CAPTCHA kiriting",
	操作成功:"Muvaffaqiyatli operatsiya",
	"用户名或密码不正确,登录失败":"Foydalanuvchi nomi yoki parol noto'g'ri, login amalga oshmadi",
	登录成功:"Kirish muvaffaqiyatli",
	充值说明:"Depozit bo'yicha ko'rsatmalar",
	请先设置支付密码:"Iltimos, avval tranzaksiya parolini o'rnating",
	复制成功:"Muvaffaqiyatli nusxalash",
	冻结机器人做单本金:"Miqdoriy mablag'larni muzlatish",
	待审核:"Tekshirish kutilmoqda...",
	成功:"Muvaffaqiyat",
	失败:"Muvaffaqiyatsizlik",
	审核中:"Ko'rib chiqilmoqda",
	在线充值:"Onlayn depozit",
	描叙:"Ta'riflang",
	POS节点挖矿LV0:"POS-LV0 tugunlarini qazib olish",
	POS节点挖矿LV1:"POS-LV1 tugunlarini qazib olish",
	POS节点挖矿LV2:"POS-LV2 tugunlarini qazib olish",
	POS节点挖矿LV3:"POS-LV3 tugunlarini qazib olish",
	POS节点挖矿LV4:"POS-LV4 tugunlarini qazib olish",
	POS节点挖矿LV5:"POS-LV5 tugunlarini qazib olish",
	银行卡提现:"Bank hisobini yechib olish",
	USDT提现:"USDT yechib olish",
	三级代理:"Uchinchi darajali agent",
	一级代理:"Birinchi darajali agent",
	二级代理:"Ikkinchi darajali agent",
	一级:"1-DARAJA",
	二级:"2-DARAJA",
	三级:"3-DARAJA",
	做单:"Savdo qilish",
	登录失败:"Kirish amalga oshmadi",
	请勿重复操作:"Tez-tez bajariladigan operatsiyalarni takrorlamang",
	邀请码不正确:"Yo'naltiruvchi kodi noto'g'ri",
	团队返佣:"Jamoa komissiyasi",
	购买机器人返佣:"Robot komissiyasi",
	本金返还:"POS qaytish",
	佣金收入:"Komissiya daromadi",
	时间:"Vaqt",
	机器人做单返还:"To'lov miqdorini aniqlang",
	涨幅:"O'zgartirish",
	市场:"Bozor",
	客服:"Xizmat",
	验证码错误:"Tasdiqlash kodi xatosi",
  付款方式:"Olib tashlash usuli",
	我的留言:"Mening xabarim",
  暂无数据: "Maʼlumot yoʻq",
  银行卡:"Bank hisobi",
  提款方式:"Olib tashlash usuli",
  修改: "O'zgartirish",
 "请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助":"Iltimos, hamyoningiz manzili to'g'ri ekanligiga ishonch hosil qiling. Agar siz manzilni noto'g'ri kiritsangiz va pulni ololmasangiz, yordam uchun o'z vaqtida onlayn mijozlarga xizmat ko'rsatish bilan bog'laning",
 连接借记卡:"Debet kartani ulang",
 银行名称:"Bank nomi",
 姓名姓氏: "Sizning ismingiz",
 IBAN号码:"IBAN raqami",
 "* 重要提示:借记卡信息必须真实有效才能取款。":"* Muhim eslatma: Bank hisobvarag'i ma'lumotlari to'g'ri va pul yechib olish uchun haqiqiy bo'lishi kerak.",
 帮助中心:"Yordam markazi",
 会员须知:"A'zo bildirishnomasi",
 "24小时内使用USDT实时取款（推荐）":"1. 24 soat ichida real vaqtda pul yechib olish uchun USDT dan foydalaning (tavsiya etiladi)",
 "银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账":"2. Bank kartasini yechib olish *Echib olish vaqti 10:00-18:00 * Pul yechib olish boshlanganidan keyin 2 soat ichida yechib olinadi *Agar siz yechib olmaslik yoki banklararo yechib olish uchun ariza topshirsangiz, u soat 10:00 da yechib olinadi. - Ertasi kuni 18:00",
 我知道了:"Men ko'ryapman",
 链接点击:"Yo'naltiruvchi ro'yxatga olish havolasi",
 确定:"Albatta",
 建立自己的团队邀请:"O'z jamoangizni yarating",
 此账号已冻结:"Bu hisob muzlatilgan",
 手机号存已存在:"Mobil telefon raqami allaqachon mavjud",
 注册账号已存在:"Ro'yxatdan o'tgan hisob allaqachon mavjud",
 请确定新密码:"Yangi parolni tasdiqlang",
 请再次输入登录密码:"Kirish parolini yana kiriting",
 密码长度不能少于6位:"Parol uzunligi 6 ta belgidan kam boʻlmasligi kerak",
 加入:"Qo'shiling",
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "Siz sotgan tugun boshqa tomon tomonidan qabul qilingan va sotishdan tushgan mablagʻ sizning hisobingizga oʻtkazilgan, iltimos tekshiring.",
 	"你出售的机器人对方已拒绝接受":"Siz sotgan tugun boshqa tomon tomonidan rad etilgan",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"Bank kartasi bog'langan",
 	USDT_TRC20提现:"USDT_TRC20 yechib olish",
 	USDT_ERC20提现:"USDT ERC20 yechib olish",
 	银行卡提现:"Bankdan pul olish",
 	邀请赠送:"Yo'naltiruvchi bonus",
 	机器人返佣:"Robot komissiyasi",
 	升级:" muvaffaqiyatli xarid ",
 	充值主网:"充值主网",
   "您被限制出售机器人,请联系客服了解详情":
     "Siz tugunlarni sotishingiz cheklangan, batafsil ma'lumot uchun mijozlarga xizmat ko'rsatish bilan bog'laning",
   交易编号: "Tranzaksiya raqami",
   消息详情:"Xabar tafsilotlari",
   个人收益累计:"To'plangan shaxsiy daromad",
   今日代理收益:"Bugungi kunda agentlik daromadi",
   代理累计收益:"Agentlikning umumiy daromadi",
   截图保存推荐给朋友:"Yo'naltiruvchi ro'yxatdan o'tish havolasini baham ko'ring va do'stlaringizni Plus500Ai platformasiga qo'shilishga taklif qiling va jamoaviy daromad komissiyasi mukofotlarini oling. Uchinchi darajali jamoa a'zolari uchun daromad komissiyasi 8% -3% -2% ni tashkil qiladi. Jamoa komissiyasi quyi bo'g'in a'zolarining kunlik daromadlari to'g'risidagi ma'lumotlarga asoslanadi va har kuni soat 00:00 dan keyin avtomatik tarzda hisob-kitob qilinadi va sizning hisob balansingizga kiritiladi.",
   复制:"Nusxalash",
   充值提示1:"1-maslahat: Minimal toʻldirish miqdori 8 USDT, siz toʻldirishingiz mumkin, aks holda sizga hisob berilmaydi",
   充值提示2:"Maslahat 2: Zaryadlash zanjirda tasdiqlanishi kerak. Muvaffaqiyatli to'ldirgandan so'ng, u hamyon balansiga taxminan bir daqiqada tushishi kutilmoqda.",
   请输入正确的充值金额:"Omonat summasi ≥ 8 USDT",
   推荐:"tavsiya eting",
   充值金额:"Omonat summasi",
   请上传付款截图:"Muvaffaqiyatli toʻlovning skrinshotini yuklang",
   交易号: "Tranzaksiya xesh raqami",
   充值ID提示:"Iltimos, Txid tranzaksiya raqamingizni kiriting",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Iltimos, hamyon manzili to'g'ri ekanligiga ishonch hosil qiling. Agar hamyon manzili noto'g'ri to'ldirilgan bo'lsa va pulni qabul qila olmaslik natijasida etkazilgan zarar foydalanuvchi tomonidan qoplanadi.",
	上传凭证:"To'lovni tasdiqlovchi hujjatni yuklang",
	充值确认: "Yuborish",
	不能为空:"Bo'sh bo'lishi mumkin emas",
  绑定钱包地址:"Hamyon manzilini bog'lash",
	备注:"Eslatma",
	银行:"Bank",
	请输入银行卡号:"Bank hisob raqamini kiriting",
	请输入你的名字:"Iltimos, ismingizni kiriting",
	银行卡号:"Bank hisob raqami",
	添加银行卡:"Bank hisobini qo'shing",
	请选择银行:"Bankni tanlang",
	请输入钱包地址:"Iltimos, hamyon manzilini kiriting",
	钱包地址:"Hamyon manzili",
	"密码错误次数过多,请等待x秒后再试":"Juda koʻp notoʻgʻri parollar, 600 soniya kuting va qayta urinib koʻring",
	"此账号已冻结,请联系客服":"Bu hisob muzlatilgan, iltimos, mijozlarga xizmat ko‘rsatish xizmatiga murojaat qiling",
	手机号格式错误:"Noto'g'ri tuzilgan telefon raqami",
	"取款须知":"Pul o'tkazish to'g'risida bildirishnoma",
	"须知1":"1. 24 soat ichida real vaqtda yechib olish uchun USDT-TRC20 dan foydalaning (tavsiya etiladi)",
	"须知2":"2. Shanba va yakshanba kunlari pul olish",
	"须知3":"*Foydalanuvchilar shanba va yakshanba kunlari pul yechib olishlari mumkin",
	"须知4":"* Dam olish kunlarida yechib olish dushanba kuni soat 10:00 dan 20:00 gacha keladi",
	我知道了:"Men ko'ryapman",
	"提现0":"1USDT=1USD ERC20",
	"提现1":"提示1：最低单笔提现100 USDT/天，单笔交易手续费5 USDT/次",
	"提现2":"TRC20提示2：每次提款最低10 USDT，每次交易手续费1 USDT。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"Bog'langan",
	去设置:"Sozlamalarga o'ting",
	注册成功:"Ro'yxatdan o'tish muvaffaqiyatli",
	汇率:"Valyuta kursi",
	请输入正确的提现金额:"Iltimos, to'g'ri yechib olish miqdorini kiriting",
	提款记录:"Olib tashlash tarixi",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1: Bog'langan hamyon manzili to'lov hamyoniga mos kelishi kerak, aks holda to'lov olinmaydi.",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"2-maslahat: Toʻldirish muvaffaqiyatli boʻlgandan soʻng, hisobingizga taxminan bir daqiqadan soʻng kelishi kutilmoqda. Iltimos, hamyon balansini tekshiring.",
	请先绑定钱包地址:"Iltimos, avval hamyon manzilini bog'lang",
	输入邮箱:"Emailni kiriting",
	邮箱已存在:"EMAIL allaqachon mavjud",
	注册邮箱已存在:"Ro'yxatdan o'tgan elektron pochta allaqachon mavjud",
	指定用户不存在:"Belgilangan foydalanuvchi mavjud emas",
	今日涨幅:"O'zgartirish",
	恭喜新用户:"Yangi foydalanuvchini tabriklaymiz ",
	快捷充币:"Omonat",
	快速买USDT:"USDT tavsiya etiladi",
	在线支付:"Onlayn to'lov",
	自动到账:"Avtomatik hisob",
	线下支付:"Oflayn to'lovlar",
	联系客服获取验证码:"Tasdiqlash kodini olish uchun mijozlarga xizmat ko'rsatish bilan bog'laning",
	获取:"Oling",
	退出成功:"Muvaffaqiyatli chiqish",
	不能低于最小充值:"Minimal omonat miqdoridan past bo'lishi mumkin emas",
	不能低于最小提现金额:"Minimal yechib olish miqdoridan past bo'lishi mumkin emas",
	最小提现:"Minimal pul olish",
	设置成功:"Muvaffaqiyatli sozlandi",
	官方充币:"Rasmiy omonat",
	银行卡充值:"Bank omonati",
	等待审核:"Tekshirish kutilmoqda",
	可提现金额不足:"Yechib olish uchun yetarli miqdorda mablag‘ yo‘q",
	未经授权:"Ruxsatsiz",
	交易密码不正确:"Tranzaksiya paroli noto'g'ri",
	提现次数不足:"Yetkazib berish vaqtlari etarli emas",
	官网:"rasmiy veb-sayti",
	账户usdt不足:"Hisobdagi USDT balansi yetarli emas",
	激活成功: "Faollashtirish muvaffaqiyatli",
	操作成功: "Muvaffaqiyatli operatsiya",
	修改成功:"Muvaffaqiyatli o'zgartirildi",
	注册说明:"Mobil OTP va Email CAPTCHA tasdiqlash uchun faqat 1 usulni tanlashi kerak va siz muvaffaqiyatli ro'yxatdan o'tishingiz mumkin",
	弹窗内容:
	"Plus500Ai miqdoriy savdo platformasiga xush kelibsiz. Har bir foydalanuvchi 1 USDT miqdoridagi roʻyxatdan oʻtish bonusidan NO.0 miqdoriy robot savdosi strategiyasi paketini xarid qilish uchun foydalanishi mumkin, u 1 kun davomida amal qiladi va 0,5 USDT bepul ishlab topadi. Boshqa miqdoriy savdo strategiyasi paketlari tegishli to'lovlarni qo'llashni talab qiladi. Foydalanuvchilar robotni sotib olgandan so'ng, u avtomatik ravishda ishga tushishidan oldin miqdoriy savdo strategiyasini faollashtirishi kerak. Har bir savdo strategiyasi boshqa amal qilish muddatiga mos keladi. Agar foydalanuvchi tegishli robot nuqtalarini sotib olgandan keyin strategiyani faollashtirish uchun bosmasa, siz hech qanday savdo daromadini olmaysiz.",
	标题1:"1.Plus500 platformasi bilan tanishish",
	标题2:"2. AI miqdoriy savdoning afzalliklari",
	标题3:"3. Nima uchun Plus500Ai ni tanlaysiz?",
	标题4:"4. Plus500Ai-ga qanday qo'shilish mumkin",
	标题5:"5.Plus500Ai bozor istiqbollari",
	标题6:"6.Plus500Ai daromad modeli",
	标题7:"7.Omonat va pul yechib olish qoidalari",
	标题8:"Battle Royale o'yin",
	内容8:"Plu500Ai platformasida jangovar royale o'yinida 8 ta xona mavjud. O'yinchilar oltin tangalar qo'yish uchun 8 xonadan istalgan xonani tanlashlari mumkin. Tizimning teskari hisobi tugagandan so'ng, o'yin tizimida qotil paydo bo'ladi. Qotil tasodifiy xonaga kirib, xonadagi odamlarni yo'q qiladi. Boshqa o'yinchilar ushbu xonada oltin tangalarni baham ko'rishlari va daromad olishlari mumkin. Battle Royal o'yinining o'yini nisbatan qisqa, o'yin bir necha daqiqa davom etadi, shuning uchun o'yinchilar uni bo'sh vaqtlarida o'ynashlari mumkin. Bo'linish miqdori = bu davrda qotil tomonidan o'ldirilgan xonalarning umumiy soni. Haqiqiy ulush miqdori = ulush miqdori * 0,9 (yo'q qilish nisbati 0,1 dan tashqari). Ulanish nisbati = haqiqiy ulush miqdori / boshqa xonalardagi umumiy tikish miqdori. Yutuq miqdori = almashish nisbati * haqiqiy tikish miqdori. 1 oltin tanga = 1 USDT va jangovar royale o'yinida ishtirok etish uchun kamida 10 USDT talab qilinadi.",
	内容1:"Plus500 savdo platformasi Plus500SEY Ltd tomonidan taqdim etilgan. Shunga ko'ra, Plus500SEY Ltd ushbu veb-saytda tasvirlangan yoki taklif qilingan moliyaviy mahsulotlarning emitenti va sotuvchisi hisoblanadi. Plus500SEY Ltd - Seyshel orollarida joylashgan kompaniya (Kompaniya № 8426415-1) o'zining Viktoriya shahridagi ofislariga ega. Kompaniya SD039 litsenziya raqamiga ega Seyshel orollari moliyaviy xizmatlar boshqarmasi (FSA) tomonidan ruxsat etilgan va nazorat qilingan va turli xil asosiy mahsulotlar bo'yicha Farqlar uchun shartnomalar (CFD) taqdim etadi. Kompaniya tez rivojlanayotgan CFD provayderi bo'lib, hozirda 2800 dan ortiq moliyaviy vositalar portfelini taklif etadi. Plus500SEY Ltd - London fond birjasining asosiy bozorida ro'yxatga olingan va shtab-kvartirasi Xayfa shahrida joylashgan Plus500 Ltd kompaniyasining sho''ba korxonasi.",
	内容2:"AI to'liq avtomatlashtirilgan miqdoriy savdoning afzalligi - model sozlamalari bo'yicha tranzaktsiyalarni amalga oshirish uchun oqilona vositalardan foydalanish. Robotda hech qanday hissiy o'zgarishlar yo'q va inson tabiati, insonning ochko'zligi va qo'rquvi va boshqa ko'plab kamchiliklar va ish jarayonidagi zaifliklarni engib o'tishga qodir. Qo'l operatsiyalari ko'pincha bozorning ko'tarilishi va pasayishi va boshqa turli omillarga ta'sir qiladi va uzoq vaqt davomida eng yaxshi foyda modeliga muvofiq ishlay olmaydi.",
	内容3:"Plus500Ai oʻzining asosiy mahsulotlarini yangi va mavjud bozorlarga kengaytirish, yangi savdo va moliyaviy mahsulotlarni chiqarish hamda mijozlar bilan aloqalarini chuqurlashtirish orqali koʻp aktivli fintech guruhi sifatida oʻz mavqeini asta-sekin mustahkamladi. O‘z aktivlaringizni himoya qiling va shifrlash kalitlaringizni sanoatning yetakchi xavfsizlik texnologiyasi bilan himoya qiling. Plus500Ai shuningdek, global saqlashni taklif qiladi, ya'ni biz tartibga solinib, tekshirilamiz va 250 million dollargacha sug'urtalanganmiz. O'z kapitalingizni nafaqat aktivlarni ushlab turish, balki ularni ishga tushirish maqsadida yo'naltiring. Plus500Ai sizga o'z mablag'laringizni Ai miqdoriy savdosidan tortib, xavfga chidamliligingizga qarab klassik asosiy xizmatlargacha bo'lgan turli yo'llar bilan joylashtirish imkonini beradi. Qo'shimcha blokcheyn muhandislarini yollamasdan o'z foydalanuvchilariga ko'proq xizmatlarni taqdim etish uchun onlayn platforma.",
	内容4:"Plus500Ai tashkil etilganidan beri faqat bitta rasmiy veb-sayt mavjud edi, WWW.PLUS500AI.COM. Ilovani yuklab olish uchun barcha foydalanuvchilar rasmiy veb-saytga kirishlari kerak, chunki boshqa veb-saytlar bizning kompaniyamiz emas. Roʻyxatdan oʻtish uchun foydalanuvchining mobil telefon raqamini, elektron pochta manzilini va tez roʻyxatdan oʻtish uchun tavsiya qiluvchining taklif kodini yuborish kerak. Mijozlar bilan barcha muloqotlar elektron pochta yoki onlayn chat orqali yozma ravishda amalga oshiriladi. Plus500 bilan elektron pochta orqali bog'lanish uchun biz bilan bog'lanish sahifasidagi shaklni to'ldiring (So'rov formasi). Anketani topshirgandan so'ng, Plus500 ariza formasida ko'rsatgan elektron pochta manzilingizga elektron pochta orqali javob yuboradi. Savollaringiz bo'lsa, mijozlarga xizmat ko'rsatish bilan bog'laning.",
	内容5:"Plus500Ai mijozlarga ishonchli va intuitiv moliyaviy savdo imkoniyatlarini taqdim etish uchun mahsulotlar, qurilmalar va mamlakatlarda ishlaydi. Biz doimo mahsulotlarimiz va jamoalarimizda mukammallikka intilganmiz. Ilg'or xususiy texnologiya va mijozlarga yo'naltirilgan yondashuv bilan bizning foydalanuvchilarimiz birinchi darajali savdo xizmati tajribasiga ega bo'lishadi.",
	内容6:"Plus500Ai katta ma'lumotlar tahlilchilari jamoasi tomonidan kriptovalyuta bozori uchun professional miqdoriy savdo strategiyalarini taqdim etadi. Ai miqdoriy savdo robotiga sarmoya kiritgan foydalanuvchilar robot bilan mos keladigan savdo strategiyalariga tayanib, miqdoriy savdo foyda olishlari mumkin. Ai miqdoriy savdo roboti Plus500Ai bozor savdo strategiyasi asosida optimal savdo vaqtini davriy ravishda tahlil qiladi va yuqori narxlarda past darajadagi sotib olish va sotish modeli orqali miqdoriy savdodan foyda oladi. Plus500Ai investitsiya qiluvchi foydalanuvchilarga har kuni optimal miqdoriy savdo strategiyalarini taqdim etadi va platforma foydalanuvchilarning miqdoriy daromadlarining 5 foizini platforma operatsion fondlari sifatida ajratib oladi.",
	内容7:"Plus500Ai hozirda USDT depozitlari va pul mablag'larini qo'llab-quvvatlaydi va agar kelajakda boshqa kanallar ochilgan bo'lsa, rasmiy veb-saytda e'lon qilinadi. Minimal depozit va yechib olish 10 USDT. Eng tez depozit va yechib olish 5 daqiqa ichida, eng sekin esa 24 soat ichida amalga oshiriladi. Foydalanuvchi pulni yechib olishda cheklov yo'q, istalgan vaqtda pul yechib olishingiz mumkin. Pul mablag'lari oddiy ish kunlarida 5 daqiqa - 24 soat ichida keladi. Dam olish kunlarida pul mablag'larini olish dushanba kuni ko'rib chiqiladi.",
	BSC提现:"USDT_BSC yechib olish",
	社区Ads收益:"Hamjamiyat reklamalari daromadi",
	Ads收益区间:"Reklama daromadi 1-1000$",
	申请Ads费用:"Ilova e'lonlari uchun to'lov",
	Ads促销地址:"Reklama reklama manzili",
	填写Ads促销地址:"Reklama reklamasi URL manzilini toʻldiring",
	申请Ads费用须知:"Reklama to'loviga ariza berish bo'yicha eslatmalar",
	须知A:"1. Foydalanuvchilar reklama videolarini yozib oladilar va ularni oʻzlarining YouTube, Facebook, kanallari yoki boshqa hamjamiyatlariga eʼlonlarni ilgari surish uchun yuklaydilar, $1-1000 bonus olish uchun ariza topshiradilar va platforma ariza topshirilgandan keyin 3 kun oʻtib Eʼlonlar video materialingizni koʻrib chiqadi va joylashtiradi. Video targ'ibot effektiga ko'ra hisobga e'lonlar bonusi Sizning hisobingiz balansi, reklama effektlari bo'lmagan videolarni ko'rib bo'lmaydi.",
	须知B:"2. Xuddi shu kanaldagi bir xil reklama reklama videosi faqat bir marta bonus uchun ariza berishi mumkin, yangi reklama video materiallari esa haftada bir marta chiqarilishi mumkin. Zararli takroriy so'rovlarni yubormang. Agar siz platformada oʻzingiz kiritmagan reklama soʻrovlarini yoki koʻrib chiqilmagan eʼlonlar materiallarini qasddan yuborsangiz, tizim aniqlaganidan keyin hisobingiz bloklanadi.",
	须知C:"3. Foydalanuvchi tomonidan yozib olingan E'lonlar video materialida platformaning asosiy kirishi va platforma funksiyasi tushuntirishi bo'lishi kerak. Video vaqti 5 daqiqadan kam emas. Kanalingizga obunachilar qancha ko'p bo'lsa, videoning reklama effekti shunchalik yaxshi bo'ladi va reklama to'lovi shunchalik yuqori bo'ladi. Maxsus reklama toʻlovlari YOUTUBE’ni misol qilib olgan holda quyidagicha:",
	须知D: "0-2K abonent: ariza miqdori 1-20$",
	须知D1:"3K-10K abonentlari: ariza miqdori 21-40$",
	须知D2:"Abonent 11K-30K: ariza miqdori 41-100$",
	须知D3:"Abonent 31K-50K: ariza miqdori 101-300$",
	须知D4:"Abonent 51K-100K: ariza miqdori 301-1000$",
	须知D5:"4. Tizim har yakshanba kuni Ads bonusli arizalarni ko'rib chiqadi va qayta ishlaydi va malakali foydalanuvchilar tizim tomonidan yuborilgan bonuslarni oladi.",
	请求审核:"Ko'rib chiqish uchun so'rov",
	申请金额:"Ariza miqdori",
	URL促销地址:"URL reklama manzili",
	提交时间:"Yuborish vaqti",
	需要更新的银行卡不存在:"Yangilanadigan bank kartasi mavjud emas",
	预计收入:"Hisoblangan daromad",
	总投资:"Jami investitsiyalar",
	购买价:"Sotib olish narxi",
	邀请:"Yo'naltiruvchi",
	积分等级折扣规则介绍3:"Ushbu chegirma miqdori faqat 2024-yil 31-dekabrgacha amal qiladi, 2025-yil 1-yanvardan keyin yangi tuzatish kiritiladi.",
	成员:"a'zolari",
	LV1佣金:"1-darajali komissiya",
	LV2佣金:"2-darajali komissiya",
	LV3佣金:"3-darajali komissiya",
	合格条件:"Bir kishi uchun kamida 8$ depozit",
	随时可退:"Toʻlovni istalgan vaqtda qaytaring",
	登录密码错误:"Kirish paroli noto'g'ri",
	充值奖金:"Depozit USDT ≥ 500USDT, bonus 3%",
	充值奖金1:"Deposit IDR ≥ 50USDT, bonus 2%",
	充值奖金2:"Deposit THB ≥ 50USDT, bonus 5% ",
	活动已过期:"Tadbir muddati tugadi, keyingi tadbirni kutmoqda",
	不能重复发送短信:"Siz 10 daqiqa ichida takroriy matnli xabarlarni yubora olmaysiz. To'g'ridan-to'g'ri ro'yxatdan o'tish uchun EMAIL CAPTCHA dan foydalanish tavsiya etiladi.",
	限购说明1:"Salom, NO.0 miqdoriy robot savdo strategiyasining har bir foydalanuvchisi uni faqat bir marta sotib olishi mumkin.",
	修改银行提示:"Pul yechib olinadigan bank hisobi bog‘langanidan keyin uni o‘zgartirib bo‘lmaydi. Agar sizga yordam kerak bo'lsa, mijozlarga xizmat ko'rsatish bilan bog'laning.",
	修改钱包地址提示:"Agar bog‘langan USDT hamyon manzilini o‘zgartirish kerak bo‘lsa, tekshirish uchun mijozlarga xizmat ko‘rsatish bilan bog‘laning.",
	注册推送消息1:"Welcome to INGSAI. After new users start a POS mining node, they will receive POS mining income every 5 minutes. The team commission income will be automatically entered into the account balance at about 00.00 per day.",
	限购说明:"Salom, ushbu turdagi miqdoriy robotlar to'plami uchun maksimal xaridlar soni 10 marta. Boshqa turdagi miqdoriy savdo paketlarini sotib olishingiz tavsiya etiladi.",
	注册协议:"(Hisob yaratish) tugmasini bosish orqali siz 18 yoshdan oshganligingizni tasdiqlaysiz. Siz bilasizki, Plus500AI o'z mahsulotlari/xizmatlari haqida tegishli ma'lumotlarni taqdim etish uchun elektron pochta manzilingiz yoki boshqa shaxsiy ma'lumotlaringizdan foydalanishi mumkin. Savdo tajribangizdan maksimal darajada foydalanishingizga yordam berish uchun sizni bozor voqealari haqida ham xabardor qilishimiz mumkin. Platformadagi (Bildirishnoma sozlamalari) va (Maxfiylik sozlamalari) yorliqlarida bildirishnoma sozlamalarini boshqarishingiz va oʻzgartirishingiz mumkin. Siz istalgan vaqtda ushbu xabarlarga obunani bekor qilishingiz mumkin. Qo'shimcha ma'lumot olish uchun bizning (Maxfiylik shartnomasini) ko'rib chiqing.",
	公司说明1:"Plus500AI Plus500 Ltd kompaniyasining roʻyxatdan oʻtgan savdo belgisidir. Plus500 Ltd quyidagi shoʻba korxonalar orqali ishlaydi:",
	公司说明2:"Plus500SEY Ltd kompaniyasi Seyshel orollari moliyaviy xizmatlar boshqarmasi tomonidan ruxsat etilgan va tartibga solingan (litsenziya № SD039).",
	公司说明3:"Plus500SEY Ltd ushbu veb-saytda tasvirlangan yoki mavjud bo'lgan moliyaviy mahsulotlarning emitenti va sotuvchisidir.",
	公司说明4:"Plus500UK Ltd moliyaviy xulq-atvor organi tomonidan ruxsat etilgan va tartibga solingan. Moliyaviy xulq-atvor organining ro'yxatga olish raqami: 509909. Cryptocurrency CFD'lari chakana foydalanuvchilar uchun mavjud emas.",
	公司说明5:"Plus500CY Ltd Kipr Qimmatli qog'ozlar va birja komissiyasi tomonidan ruxsat etilgan va tartibga solingan (avtorizatsiya kodi: 250/14). Cryptocurrency CFD'lari Buyuk Britaniyaning chakana investorlari uchun mavjud emas.",
	公司说明6:"Plus500AU Pty Ltd quyidagi litsenziyalarga ega: ASIC tomonidan berilgan AFSL (№ 417727), Yangi Zelandiya FMA tomonidan chiqarilgan FSP (№ 486026) va Janubiy Afrika FSCA tomonidan berilgan vakolatli moliyaviy xizmatlar provayderi (№ 47546).",
	公司说明7:"Plus500EE AS ​​Estoniya moliyaviy nazorat organi tomonidan ruxsat etilgan va tartibga solingan (litsenziya № 4.1-1/18).",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) Singapur Monetar organi tomonidan berilgan kapital bozori xizmatlari litsenziyasiga ega bo'lib, unga kapital bozori mahsulotlari bilan savdo qilish imkonini beradi (litsenziya raqami CMS100648).",
	公司说明9:"Plus500AE Ltd Dubay Moliyaviy Xizmatlar Authority (F005651) tomonidan ruxsat etilgan va tartibga solingan.",
	加入我们的社群:"Hamjamiyatimizga qo'shiling",
	PLUS500集团:"PLUS500 GURUHLARI",
	受监管的操作:"TARTIBIY BO'LGAN AMALIYATLAR",
	健全的全球监管框架:"Kuchli global tartibga solish bazasi",
	治理和社会责任:"BOSHQARUV VA IJTIMOIY MAS'uliyat",
	底部说明:"Plus500 o'z biznesining barcha jabhalarida mas'uliyatli va barqaror ishlashga sodiq qoladi va bu yondashuvni o'z burchi va samarali boshqaruvning muhim qismi deb biladi. Plus500 xalqimiz, mijozlarimiz, mahalliy hamjamiyat va xayriya tashkilotlari hamda aktsiyadorlarimiz uchun sezilarli qiymat yaratish maqsadida bir qator ESG tashabbuslariga sodiqdir.",
    投资者联系方式:"INVESTOR MUROJATLARI",
	投资者关系主管:"Investorlar bilan aloqalar bo'limi boshlig'i",
	AI智慧型量化交易:"AI aqlli miqdoriy savdo",
	订单执行快速可靠:"Buyurtmani bajarish tez va ishonchli",
	全天候自动分析行情:"Kun davomida avtomatik bozor tahlili",
	交易受监管低风险:"Savdo tartibga solinadi va past xavf",
	稳定策略高效收益:"Barqaror strategiya va samarali daromad",
	取款快速且安全:"Pulni tez va xavfsiz yechib oling",
	游戏说明:"O'yinga kirish va pul ishlash uchun zarur bo'lgan minimal balans - 10 USDT",
	
	
}
