<template>
  <div class="page">
    <div class="header">
    	<title>Plus500 WebTrader | Leading CFD Platform | Online Trading </title>
    	<div></div>
		
    	<div style="margin-top:10px; height:30px;width:38%; text-align: center;  border-radius: 10px; border: 1px solid #cccccc;background-color:#fff;box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35);">{{
    	      $t('语言切换')}}<img class="msg-icon" style="width: 15px;height: 15px;vertical-align:middle; " src="@/assets/lang2.png" alt="" @click="changlang()" /></div>
      </div>
      <div class="content" style="text-align: center;">
        <img class="logo" src="../../assets/img/logo111.png" alt="" />
   
      <van-form>
        <div class="phone">
         
          <van-field
            class="input"
			style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;"
            v-model="username"
            :placeholder="$t('输入邮箱')"
          />
		  
        </div>
        <div class="captcha">
          <van-field style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;" v-model="captcha" :placeholder="$t('输入验证码')" />
		  
          <van-button class="textBtn" plain type="info" @click="getcode()">
			<span v-show="show">{{tishi}}</span>
            <span v-show="!show">{{count}} s</span>
          </van-button>
        </div>
        <van-field
          class="input"
		  style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;"
          v-model="password"
          type="password"
          :placeholder="$t('输入密码')"
        />
		
        <van-field
          class="input"
		  style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;"
          v-model="password2"
          type="password"
          :placeholder="$t('再次输入密码')"
        />
		
      </van-form>
    </div>
<ROBOT />
    <div style="width:95%;margin-left:10px; text-align: center;">
      <van-button class="btn" round block type="info" @click="subpasswd">{{
        $t('确认')
      }}</van-button><br>
    </div>
	<div style="text-align: center;">
	  <van-button class="getBack" round block type="info" @click="getBack">{{
	    $t('返回')
	  }}</van-button>
	</div>
  </div>
  
</template>

<script>
	import { NavBar, Button, Field,Toast } from "vant";
	import ROBOT from '@/components/robot'
	import { apipaypassword,apiUser_mas2,apipaysms,apiRegss,apiForget} from "@/http/api/";
export default {
  name: 'welcome',
	components: {
	 
	  ROBOT
	 
	},
  data() {
    return {
       value: '+90',
	  option1: [
	  { text: "Turkey +90", value: "+90" },
	 
	    ],
      username: '',
      password: '',
      password2: '',
      captcha: '',
      count:60,
      show: true,
      timer: null,
      tishi: this.$t("OTP") ,
    }
  },

  methods: {
    captchaEvent() {
      this.textContent = '12miao'
    },
	changlang () {
	  this.$router.push('/lang')
	},
    getBack() {
      this.$router.back()
    },
    subpasswd(){
    		if(this.password != this.password2){
    			Toast.fail(this.$t('两次密码不一致'));
    			return;
    		}
    		if(!this.captcha){
    			Toast.fail(this.$t('请输入验证码'));
    			return;
    		}
    		apiForget({ 
    		  passwd:this.password2,	  
    		  code:this.captcha,
			  username:this.username,
    		  type:"reset"
    		}).then((res) => {
    		 if(res.status != 0){
    			Toast.success(this.$t(res.info));
    			this.$router.replace("/my");
    		 }else{
				 Toast.fail(this.$t(res.info)); 
    		 }
    		});
    	},
	getCode2(){
	  	const TIME_COUNT = 60;
			 if (!this.timer) {
			   this.count = TIME_COUNT;
			   this.show = false;
			   this.timer = setInterval(() => {
			   if (this.count > 0 && this.count <= TIME_COUNT) {
				 this.count--;
				} else {
				 this.show = true;
				 clearInterval(this.timer);
				 this.timer = null;
				}
			   }, 1000)
			  }
	}  ,
	getcode() {
		  	    if (!this.username) {
		  	      Toast.fail(this.$t("邮箱不能为空"));
		  	      return;
		  	    }
		  	    	
		  	    apiRegss({
		  	      username: this.username,
		  		 
		  		  optionval:this.optionval,
				  
		  	    }).then((res) => {
		  	      console.log(res);
		  	      if (res.status) {
		  			  
		  	        Toast.success(this.$t(res.info));
		  			  
		  			 this.getCode2();
		  	      } else {
		  	        Toast.fail(this.$t(res.info));
		  	      }
		  	    });
		  	  },
  }
}
</script>

<style lang="scss" scoped>
.page {
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    .close {
      width: 28px;
      height: 28px;
    }
    .img {
      width: 40px;
	  margin-top: 40px; 
	  text-align:left; 
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
.van-field {
		background-color: #fff !important;
	}
	.van-field__control{
		background-color: #fff !important;
	}
	.van-dropdown-menu .van-dropdown-menu__bar{
		background-color: #fff !important;
	}
  .btn {
    font-size: 40px;
    font-weight: bold;
    height: 103px;
    background: #0c2780;
    border: none;
    border-radius: 5px;
    color: #fff;
  }
  .getBack {
    font-size: 40px;
    font-weight: bold;
    height: 103px;
    background: #ffffff;
    
    color: #0c2780;
  }
  .phone {
    display: flex;
  }
  .captcha {
    height: 90px;
    padding: 10px;
    margin-bottom: 30px;
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.20000000298023224);
    border-radius: 20px;
    .textBtn {
      width: 400px !important;
    }

    ::v-deep .van-button--plain.van-button--info {
      color: #FFFFFF;
    }
  }
  .content {
 //   margin-top: 90px;
    padding: 0 36px;
	.logo {
	  width: 724px;
	  height: 286px;
	 // margin-top: 53px;
	 // margin-bottom: 5px;
	}
    .prefix {
      width: 180px;
      margin-right: 30px;
      ::v-deep input.van-field__control {
        color: #0d9135;
      }
    }
  }
  ::v-deep .van-button--plain {
    background-color: #131587;
  }
  ::v-deep.van-button--info {
    border: none;
  }
  ::v-deep input.van-field__control {
    color: #000;
  }
}
.bt {
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20000000298023224);
}
</style>
