export default {
  tabbar: {
    首页: 'ホーム',
    市场: '市場',
    任务: '任务',
    团队: 'チーム',
    我的: '私の'
  },
  navbar: {
    充值: 'リチャージ',
    提现: '撤退する',
    帮助: 'よくある質問',
    分享: '招待する',
	推广: '広告収入',
    活动: 'おまけ',
    游戏: 'ゲーム',
  },	 
  payment: {
    付款方式详情: 'お支払い方法の詳細',
    银行卡: '银行卡',
    钱包地址: 'ウォレットアドレス',
    交易密码: '取引パスワード',
    保存: '保存する'
  },
  market: {
    市场: '市場',
    名称: '名',
    价格: '価格',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: '我知道了'
  },
  邀请好友注册领取佣金:"友達を招待して登録してコミッションを受け取りましょう",
  领取:"受け取る",
  未满足条件:"条件が満たされていません",
  总收入:"総収入",
  投资建议:"※より大きな利益を得るために複利投資計画を実行してください",
  语言切换: '言語',
  POS区块节点挖矿: 'Ai定量トレーディング詳細',
  节点类型: 'ロボット\nタイプ',
  加入价格: '購入\n価格',
  质押资金: 'POS\n資金',
  质押周期: '期間\n(日)',
  每日收入: '毎日の\n収入',
  累计积分: '累積\nポイント',
  代理等级: 'エージェント\nレベル',
  下级折扣比: '従属\n割引',
  代理折扣比: 'エージェント\n割引',
  下属等级: '従属\nレベル',
  代理佣金比率: 'エージェント\nコミッション\nレート',
  下属每日收入: '部下\n日次\n収益',
  代理佣金: 'エージェント\nコミッション',
  引用对象: '参照\nオブジェクト',
  积分等级折扣规则: 'ポイントティア割引規定',
  积分等级折扣规则介绍: 'Ai定量取引に参加することに成功したユーザーには対応するアカウントポイントが付与され、エージェントの上位ユーザーも対応ポイントを増加させることができます（直属部下の15％に適用） アカウントポイントの合計が対応条件を満たした場合、エージェントのレベルがアップしますアカウントは改善されます。異なるレベルのエージェント アカウントは、異なる割合の価格割引を受けることができます。 対象となるポイント割引の詳細は以下の通りです。',
  积分等级折扣规则介绍2: '異なる関係 (直接) を持つユーザーは、Ai 定量的トランザクションへの部下の参加の割合に基づいて割引とエージェント レベルの割引を受けます。 他の上級幹部も、自身のエージェントに応じた割引レベルを受けることになります。 Ai 定量的トランザクションに参加する場合の価格割引: 対応するエージェント レベルを持つユーザーは、Ai 定量的トランザクションに参加する際に、対応するエージェント レベルの優先権を受け取ります。',
  团队: 'チーム',
  代理佣金制度: '代理店手数料制度',
  代理佣金说明详情: 'Ａで得られるＬＶ１剤の割合：8％、Ａで得られるＬＶ２剤の割合：3％、Ａで得られるＬＶ３剤の割合：2％。 例: B、C、D の 1 日あたりの利益: 100 USD、A が獲得した LV1 エージェントの報酬: 8%*100=8 USD。 A が獲得した LV2 エージェントの報酬: 3%*100=3 USD。 A が獲得した LV3 エージェントの報酬: 2%*100=2 USD。 コミッション収入の合計は、8+3+2=13 米ドルです。',
  代理结构图示: 'プロキシ構造図',
  代理结构图示说明: 'A はあなたを表し、A は B を招待し (A の部下は B)、B は C を招待し (B の部下は C)、C は D を招待します (C の部下は D)。 A は複数の部下 (B1、B2、B3、B4、B5、B6 など) を持つことができ、B (B1、B2、B3) も複数の部下 (C1、c2、c3、c4、c5、c6 など) を持つことができます。 ） CD...',
  高级代理佣金说明: 'シニアエージェントコミッションの指示',
  高级代理佣金说明详情: 'A はあなたを表し、A は B を招待し (A の部下は B)、B は C を招待し (B の部下は C)、C は D を招待します (C の部下は D)。 上位エージェントは、自身のアカウントと下位アカウントに関与するノードマイニングの合計額の比率に応じて、異なる代理手数料収入 (%) を取得します。 A は、部下メンバーの毎日の収入を比較して、対応する代理店報酬の割合を取得します。 参加ノードは稼働中である必要があります。 代理店収入は、すべての財務費用に対して INGSAI MINING によって提供され、通常の従属メンバーの毎日の収入には影響しません。',
  高级代理佣金说明详情2: '例: 上位エージェント (A) の総採掘量 > 下位メンバー (B/C/D) の総採掘量の 30% (例: A > B/C/D の 30%)',
  积分: '積分',
  规则: 'ルール',
  会员级别: '会員レベル',
  经验: '合計点',
  代理信息: '会員情報',
  更多: 'もっと',
  团队奖励: 'チームコミッション',
  昨天: '昨日の',
  本星期: '今週です',
  全部: '全て',
  机器人说明: 'Plus500Ai のユーザーは複数の定量取引パッケージを同時に購入できますが、NO.1 ロボットはアカウントごとに 10 回しか購入できません。 覚えておいてください: 定量ロボットを購入した後、クリックして戦略を有効にすることを忘れないでください。そうしないと収入が得られません。 クオンツ ロボットの有効期限は購入時刻に基づいて計算されます。期限内にクオンツ トレーディング戦略をアクティブ化してください。有効期限が切れた後にクオンツ ロボットがアクティブ化されていない場合、クオンツ トレーディング戦略パッケージの購入コストが失われます。 !! Plus500Ai クオンツ取引に参加されるすべてのユーザーは、この規制をよくお読みください。 ！',
  会员体验: 'メンバーの経験',
  机器人采购: 'ロボットの購入',
  机器人启动押金: 'ノード POS 金額',
  机器人礼物: 'ノードギフト',
  机器人订单: 'ノードの順序',
  一次性总和: '見せる',
  总计: '合計',
  
  被激活: 'すでにPOS',
  工作中: '活性化済み',
  暂停: 'POSを停止',
  结束: '失効する',
  刷新: '更新する',
  来源: '出典',
  价格: 'ロボットパック価格',
  启动押金: 'POS 金額',
  剩余时间: '残り時間',
  激活时间: '起動時間',
  号: 'ナンバー',
  我的资产: '私のアセット',
  退出: 'サインアウト',
  邀请码: '招待コード',
  我的钱包余额: '私の財布の残高',
  我的机器人仓库: '私のロボット一覧',
  收益面板: '収益パネル',
  去提款: '撤退する',
  昨天代理返利: '昨日の代理店手数料',
  累计代理返利: '累計代理店手数料',
  今天机器人收益: '本日の取引利益',
  累计机器人收益: '累計トレーディング収入',
  累计机器人回扣: '累計取引委員会',
  累计总回报: '累積総収入',
  自动订单机器人数量: 'クオンツトレーディングロボットを起動',
  我的付款方式: '私の支払い方法',
  个人收入记录: '個人の収入',
  团队收入记录: 'チーム収入',
  记录详情:'レコードの詳細',
  充值记录: '記録を補充する',
  我的团队成员: '私のチームメンバー',
  活动中心: 'ルール紹介',
  修改登录密码: 'ログインパスワード設定',
  交易密码管理: '取引パスワード設定',
  机器人: 'Aiトレーディング戦略',
  机器人性能: 'ロボットパネル',
  机器人价格: '取引戦略の価格',
  有效期: 'トレード有効期間',
  钱包地址: 'ウォレットアドレス',
  天: '日',
  去购买: '買う',
  买个机器人: 'クオンツ取引ロボットを購入する',
  我的钱包余额: '私の口座残高',
  预计日收益: '推定日収',
  启动保证金: 'スタートアップデポジット',
  请输入购买数量: '購入数量を入力してください',
  个机器人: 'クオンツ取引ロボット',
  机器人购买后需要激活才能工作: 'ノードを購入した後、利益を得るには対応する金額の POS が必要です',
  机器人激活需要从余额中扣除相应的启动押金:
    'ノード POS マイニングは、アカウント残高から対応する POS 金額を差し引く必要があります',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    'ノードのPOSマイニングが一時停止されたときにすぐに返還できるPOSの量',
  购买: '買う',
  点击去了解我们: '定量的取引',
  购买机器人: 'ロボットを買う',
  快速入口: '快速入口',
  '日/个人受益': '日/个人受益',
  购买教程: 'AI取引ロボット一覧',
  激活金额: 'POS手数料',
  订单数量: '注文数量',
  我们的合作伙伴: '私たちの暗号市場パートナー',
  提款: 'お金を引き出す',
  提款金额: '引き出し金額',
  你还没有添加银行卡: '你还没有添加银行卡',
  提现说明: '出金の指示',
  说明1: '1.ワンクリックで選択するか、手動でチャージ金額を入力できます',
  说明2: '2.USDT（TRC20）がおすすめ、送金手数料が一番安い',
  说明3: '3.カスタマーサービスの営業時間は、中央アメリカ時間の月曜日から金曜日の午前 8 時から午後 6 時です。',
  说明4: 'USDT を使用してリチャージするユーザーは、成功した送金と TXID の正しいスクリーンショットをアップロードする必要があり、プラットフォームがレビューに合格した後にアカウントが正常に受信されます。',
  说明5: '4.最低リチャージは 8 USDT です。これは、システムがアカウントをすばやく確認して処理するのに便利です。',
  说明6: '5.充電についてご不明な点がございましたら、オンライン カスタマー サービスまでお問い合わせください。',
  确认提款: '出金を確認する ',
  请输入手机号码:'電話番号',
  请输入提现金额: '引き出し金額 ',
  提现费:'出金手数料',
  提现须知: '出金規則をよくお読みください',
  TIPS1: '1:毎日の出金審査時間は、月曜日から金曜日の午前 8 時から午後 6 時までです。 引き出しは週末に行うことができ、引き出しの審査時間は月曜日です',
  TIPS2: '2:7X24時間体制、いつでも出金、遅くとも24時間以内に口座到着、最短5分以内に口座到着',
  TIPS3: '3:オンライン カスタマー サービス時間: 月曜日から金曜日の AM8 から PM6',
  TIPS4: '4:USDT送金のコストにより、プラットフォームはユーザーがお金を引き出すときに引き出し手数料を請求します',
  TIPS5: '5:最低出金額は10USDT',
  钱包余额: 'ウォレット残高',
  输入数量:"数量を入力してください",
  数量:"量",
  手机号码:"電話番号",
  手机号码已存在:"携帯電話番号が登録されています",
  邮箱:"メール",
  输入手机号码: '携帯番号を入力',
  请输入登录密码: 'ログインパスワード',
  登录: 'ログイン',
  输入账号: '口座番号を入力してください',
  密码: 'パスワード',
  点击注册: 'アカウントをお持ちでない場合は、今すぐ作成してください。',
  忘记密码: 'パスワードを忘れた',
  重新登录: '再ログイン',
  密码找回成功: 'パスワードが正常に取得されました',
  找回成功请重新登录: '找回成功 请重新登录正常に取得しました。もう一度ログインしてください',
  确认: '確認する',
  发送验证码: '確認コードを送信する',
  再次输入密码: 'パスワードを認証する',
  输入验证码: 'メールアドレスのCAPTCHAを入力してください',
  输入手机验证码:"モバイルOTPを入力する",
  输入手机号: '携帯電話番号を入力',
  输入密码: 'ログインパスワード',
  快速注册: 'クイック登録',
  注册: 'レジスター',
  立即登录: 'すでにアカウントを持っています。クリックしてログインします',
  请输入邮箱: 'Gmail アドレス',
  输入用户名: 'ユーザー名を入力して下さい',
  请输入邀请码: '紹介コードを入力',
  返回: '戻る',
  密码找回失败: 'パスワードの回復に失敗しました',
  输入的信息有误: '入力した情報が正しくありません',
	获取验证码:"CAPTCHA",
	手机号码不能为空:"電話番号は空白にできません",
	账号不存在:"アカウントが存在しません",
	发送成功:"正常に送信されました",
	今日收益:"（現在の収入）",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"こんにちは、POS を途中で終了すると、システムは 5% の POS 管理手数料を請求します。 特別なリマインダー: 無料ノード体験の POS が一時停止された後は、再度取得することも、料金を取得することもできなくなります! !",
	我同意:"同意します",
	用户账号ID:"アカウントID",
	取消:"キャンセル",
	日收益:"日々の収入",
	请输入购买数量:"購入個数を入力してください",
	数量不能为空:"数量を空にすることはできません",
	加载中:"読み込み中...",
	唯一码:"ロボットコーディング",
	未激活:"非活性",
	激活:"戦略の開始",
	购买时间:"購入時間",
	"钱包余额不足，激活该机器人失败":"口座残高が不足しているため、ノード POS マイニングを実行できません",

	运行时长:"ランタイム",
	签约购买:"残高購入",
	系统赠送:"システムギフト",
	状态:"ステート",
	正常:"普通",
	我的机器人:"私のクオンツ取引ロボット",
	一级会员:"レベル1",
	二级会员:"レベル2",
	三级会员:"レベル3",
	人:"",
	充值客服:"充值客服",
	充值:"リチャージ",
	提现:"撤退する",
	提款密码:"出金パスワード",
	设置交易密码:"取引パスワードの設定",
	登录密码:"ログインパスワード",
	请输入交易密码:"取引パスワードを入力",
	再次请输入交易密码:"取引パスワードの確認",
	确认: '確認',
	手机号码不能为空:"電話番号は空白にできません",
	两次密码不一致:"2 つのパスワードが一致しません",
	请输入验证码:"確認コードを入力してください",
	操作成功:"操作成功",
	"用户名或密码不正确,登录失败":"ユーザー名またはパスワードが正しくありません。ログインに失敗しました",
	登录成功:"ログイン成功",
	充值说明:"再充電の説明",
	请先设置支付密码:"最初に取引パスワードを設定してください",
	复制成功:"正常にコピー",
	冻结机器人做单本金:"クオンツファンドの凍結",
	待审核:"審査を受ける...",
	成功:"成功しました",
	失败:"失敗する",
	审核中:"レビュー中",
	在线充值:"オンラインでリチャージ",
	描叙:"説明する",
	POS节点挖矿LV0:"POS-LV0 ノードマイニング",
	POS节点挖矿LV1:"POS-LV1 ノードマイニング",
	POS节点挖矿LV2:"POS-LV2 ノードマイニング",
	POS节点挖矿LV3:"POS-LV3 ノードマイニング",
	POS节点挖矿LV4:"POS-LV4 ノードマイニング",
	POS节点挖矿LV5:"POS-LV5 ノードマイニング",
	银行卡提现:"银行卡提现",
	USDT提现:"USDT出金",
	三级代理:"LV3プロキシ",
	一级代理:"LV1プロキシ",
	二级代理:"LV2プロキシ",
	一级:"レベル1",
	二级:"レベル2",
	三级:"レベル3",
	做单:"ノードマイニング",
	登录失败:"ログインに失敗しました",
	请勿重复操作:"頻繁な操作を繰り返さない",
	邀请码不正确:"招待コードが正しくありません",
	团队返佣:"チームコミッション",
	购买机器人返佣:"ロボットコミッション",
	本金返还:"POS リターン",
	佣金收入:"コミッション収益",
	时间:"時間",
	机器人做单返还:"POS 金額の払い戻し",
	涨幅:"増加する",
	市场:"市場",
	客服:"仕える",
	验证码错误:"確認コード エラー",
  付款方式:"USDT出金アドレス",
	我的留言:"私のメッセージ",
  暂无数据: 'データなし',
  银行卡:'银行卡',
  修改: '改訂',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'ウォレットアドレスが正しいことを確認してください.アドレスが間違って入力され、お金を受け取ることができない場合は、オンラインカスタマーサービスに連絡してください.',
 连接借记卡:"连接借记卡",
 银行名称:'銀行名',
 姓名姓氏: '名前',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'*重要な注意事項: お金を引き出す前に、銀行口座情報が真実で有効である必要があります。。',
 帮助中心:'ヘルプセンター',
 会员须知:'会員通知',
 '24小时内使用USDT实时取款（推荐）':'1. 24小时内使用USDT实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'わかりました',
 链接点击:'紹介登録リンク',
 确定:'确定',
 建立自己的团队邀请:'独自のチームへの招待状を作成する',
 此账号已冻结:"このアカウントは凍結されました",
 手机号存已存在:"携帯電話番号は既に存在します",
 注册账号已存在:"登録済みアカウントは既に存在します",
 请确定新密码:"新しいパスワードを確認してください",
 请再次输入登录密码:"ログインパスワードの確認",
 密码长度不能少于6位:"パスワードの長さは 6 文字未満にすることはできません",
 加入:'参加する',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的节点对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的节点对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"銀行口座が使用されました",
 	USDT_TRC20提现:'USDT_TRC20 出金',
 	USDT_ERC20提现:'USDT_ERC20 出金',
 	银行卡提现:'銀行口座の引き出し',
 	邀请赠送:'招待ボーナス',
 	机器人返佣:'ロボットコミッション',
 	升级:' 成功した購入 ',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "取引番号",
   消息详情:"メッセージの詳細",
   个人收益累计:"累計個人所得",
   今日代理收益:"今日の代理店収入",
   代理累计收益:"代理店累計収入",
   截图保存推荐给朋友:"紹介登録リンクを共有し、友達を Plus500Ai プラットフォームに招待して、チーム収入コミッション報酬を獲得しましょう。 3 レベルのチームメンバーの収入コミッションは 8%-3%-2% です。 チームコミッションは、下位メンバーの日々の収入データに基づいて、毎日午前0時以降に自動的に決済され、アカウント残高に入力されます。",
   复制:"コピー",
   充值提示1:"ヒント 1: 最低リチャージは 10 USDT です。リチャージできます。それ以外の場合は入金されません。",
   充值提示2:"ヒント 2: リチャージはチェーン上で確認する必要があります リチャージが成功した後、約 1 分でウォレットの残高に到着する予定です。",
   请输入正确的充值金额:"入金額が8USDT以上",
   推荐:"お勧め",
   充值金额:"入金額",
   请上传付款截图:"支払いが完了したスクリーンショットをアップロードしてください",
   交易号: 'トランザクションハッシュ番号',
   充值ID提示:"Txid トランザクション シリアル番号を貼り付けてください",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"ウォレットアドレスが正しく入力されていないことを確認してください. 間違ったウォレットアドレスの入力によりお金を受け取ることができなかった場合の損失は、ユーザーが負担します.",
	上传凭证:"支払い証明書をアップロードする",
	充值确认: "送信",
	不能为空:"空にできません",
  绑定钱包地址:'バインドウォレットアドレス',
	备注:"リマーク",
	请输入银行卡号:"请输入银行卡号",
	请输入你的名字:"名前を入力してください",
	银行卡号:"銀行口座",
	添加银行卡:"銀行口座をバインドする",
	请选择银行:"銀行を選択してください",
	请输入钱包地址:"ウォレットアドレスを入力",
	钱包地址:"ウォレットアドレス",
	"密码错误次数过多,请等待x秒后再试":"間違ったパスワードが多すぎます。600 秒待ってからもう一度お試しください",
	"此账号已冻结,请联系客服":"このアカウントは凍結されています。カスタマー サービスにお問い合わせください",
	手机号格式错误:"不正な電話番号",
	"取款须知":"退会のお知らせ",
	"须知1":"1. 24時間以内のリアルタイム出金にはUSDT-TRC20を使用（推奨）",
	"须知2":"2. 土曜・日曜の引き落とし",
	"须知3":"*ユーザーは土曜日と日曜日にお金を引き出すことができます",
	"须知4":"*週末の出金は、月曜日の午前 10 時から午後 8 時に届きます",
	我知道了:"分かりました",
	"提现0":"1USDT=1USD ERC20",
	"提现1":"提示1：最低单笔提现100USDT/天，单笔交易手续费5USDT/次",
	"提现2":"TRC20提示2：每次提款最低10USDT，每次交易手续费1USDT。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"既にバインドされている",
	去设置:"設定に移動",
	注册成功:"登録完了",
	汇率:"為替レート",
	请输入正确的提现金额:"正しい出金金額を入力してください",
	提款记录:"出金履歴",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"最初にウォレットアドレスをバインドしてください",
	输入邮箱:"メールアドレスを入力して",
	邮箱已存在:"メールボックスは既に存在します",
	注册邮箱已存在:"登録済みのメールアドレスは既に存在します",
	指定用户不存在:"指定されたユーザーは存在しません",
	今日涨幅:"変化する",
	恭喜新用户:"おめでとう新しいユーザー ",
	快捷充币:"デポジット",
	快速买USDT:"USDTリチャージ",
	在线支付:"オンライン支払い",
	自动到账:"自動口座",
	线下支付:"オフライン決済",
	联系客服获取验证码:"カスタマー サービスに連絡して確認コードを取得する",
	获取:"入手",
	退出成功:"正常に終了",
	不能低于最小充值:"最小リチャージより低くすることはできません",
	不能低于最小提现金额:"最低出金額よりも低くすることはできません",
	最小提现:"最低出金額",
	设置成功:"正常に設定されました",
	官方充币:"制度保証金",
	银行卡充值:"銀行リチャージ",
	等待审核:"審査待ち",
	可提现金额不足:"引き出し可能な金額が不十分",
	未经授权:"無許可",
	交易密码不正确:"取引パスワードが正しくありません",
	提现次数不足:"出金回数が足りない",
	官网:"官网",
	账户usdt不足:"アカウントの USDT 残高が不足しています",
	激活成功: "POSの成功",
	操作成功: "成功した操作",
	修改成功:"正常に変更されました",
	注册说明:"モバイル OTP と電子メール CAPTCHA では、検証方法を 1 つ選択するだけで、正常に登録できます",
	弹窗内容:
	"Plus500Ai クオンツ取引プラットフォームへようこそ. 各ユーザーは 1 USDT の登録ボーナスを使用して、1 日間有効で無料で 0.5 USDT を獲得できる NO.0 クオンツ ロボット取引戦略パッケージを購入できます。 他の定量的取引戦略パッケージを使用するには、対応する手数料が必要です。 ユーザーがロボットを購入した後、ロボットが自動的に実行される前に、定量的取引戦略をアクティブ化する必要があります。各取引戦略は異なる有効期間に対応しています。 ユーザーが対応するロボット ポイントを購入した後にクリックして戦略をアクティブ化しない場合、トレーディング収入は得られません。",
	标题1:'1.Plus500プラットフォームの紹介',
	标题2:'2. AIクオンツトレードのメリット',
	标题3:'3. Plus500Aiを選ぶ理由?',
	标题4:'4. Plus500Aiの入会方法',
	标题5:'5.Plus500Ai市場展望',
	标题6:'6.Plus500Aiの収益モデル',
	标题7:'7.入出金規制',
	标题8:"バトルロイヤルゲームプレイ",
	内容8:"Plu500Ai プラットフォームのバトル ロイヤル ゲームには 8 つの部屋があり、プレイヤーは 8 つの部屋の中から任意の部屋を選択して金貨を入れることができます。システムのカウントダウンが終了すると、ゲーム システムに殺人者が表示されます。殺人者はランダムに部屋に入ります。他のプレイヤーはこの部屋の金貨を共有して利益を得ることができます。 バトル ロイヤル ゲームのゲームプレイは比較的短く、ゲームは数分しか続かないため、プレイヤーは自由な時間にプレイできます。 分割の量 = この期間に殺人者によって殺された部屋の総数。 実際のシェア額 = シェア額 * 0.9 (破壊率 0.1 を除く)。 シェア率 = 実際のシェア額 / 他のルームでの合計ベット額。 勝利金額 = 分配率 * 実際の賭け金。 1 ゴールド コイン = 1 USDT で、バトル ロイヤル ゲームに参加するには最低 10 USDT が必要です。",
	内容1:"Plus500 取引プラットフォームは Plus500SEY Ltd によって提供されます。 したがって、Plus500SEY Ltd は、このウェブサイトで説明または提供されている金融商品の発行者および販売者です。 Plus500SEY Ltd は、セーシェルを拠点とする会社 (会社番号 8426415-1) で、ビクトリア州にオフィスを構えています。 同社はセーシェル金融サービス庁 (FSA) からライセンス番号 SD039 の認可および監督を受けており、さまざまな基本商品に関する差額契約 (CFD) を提供しています。 同社は急速に成長している CFD プロバイダーであり、現在 2,800 を超える金融商品のポートフォリオを提供しています。 Plus500SEY Ltd は、ロンドン証券取引所のメイン市場に上場されており、ハイファに本社を置く Plus500 Ltd の子会社です。",
	内容2:'AI完全自動定量取引の利点は、モデル設定に従って合理的なツールを使用して取引を操作できることであり、ロボットは感情的な変化がなく、人間の性質、人間の貪欲と恐怖、その他の操作プロセスにおける多くの欠点や弱点を克服できます。 手作業による経営は、相場の騰落やその他の様々な要因に影響されることが多く、長期にわたって最適な収益モデルに沿った運営ができません。',
	内容3:"Plus500Ai は、コア製品を新規市場および既存市場に拡大し、新しい取引および金融商品を発売し、顧客との関わりを深めていくことで、マルチアセット フィンテック グループとしての地位を徐々に強化してきました。 業界をリードするセキュリティ技術で資産を保護し、暗号化キーを保護します。 Plus500Ai はグローバル保管も提供しています。つまり、当社は規制および監査を受けており、最大 2 億 5,000 万ドルの保険が適用されています。 資産を保有するだけでなく、それを活用するという目標を持って資本を活用します。 Plus500Ai を使用すると、リスク許容度に応じて、Ai 定量取引から古典的なプライマリ サービスまで、さまざまな方法で資金を展開できます。 追加のブロックチェーン エンジニアを雇うことなく、自社のユーザーにより多くのサービスを提供できるオンライン プラットフォーム。",
	内容4:"Plus500Ai の設立以来、公式 Web サイトは WWW.PLUS500AI.COM の 1 つだけです。 他のウェブサイトは当社のものではないため、すべてのユーザーは公式ウェブサイトにログインしてアプリをダウンロードする必要があります。 登録するには、ユーザーの携帯電話番号、メールアドレス、推薦者の招待コードを送信する必要があり、すぐに登録できます。 顧客とのコミュニケーションはすべて、電子メールまたはオンライン チャットを通じて書面で行われます。 Plus500へメールでのお問い合わせは、お問い合わせページのフォーム（お申込みフォーム）にご記入ください。 申込フォーム送信後、Plus500より申込フォームにご指定いただいたメールアドレスに直接メールにて返信させていただきます。 ご不明な点がございましたら、カスタマーサービスまでお問い合わせください。",
	内容5:'Plus500Ai は、顧客に信頼性が高く直感的な金融取引の機会を提供するために、製品、デバイス、国を超えて運営されており、製品とチームの卓越性を常に追求してきました。 高度な独自テクノロジーと顧客中心のアプローチにより、当社のユーザーは一流の取引サービス体験を享受できます。',
	内容6:"Plus500Aiは、上級データアナリストチームによる仮想通貨市場の専門的な定量取引戦略を提供し、Ai定量取引ロボットに投資するユーザーは、ロボットに合わせた取引戦略に依存して定量取引利益を得ることができます。 Ai定量取引ロボットは、Plus500Aiの市場取引戦略に基づいて最適な取引時間を周期的に分析し、低価格での売買モデルを通じて定量取引利益を獲得します。 Plus500Aiは、投資ユーザーに毎日最適な定量的取引戦略を提供し、プラットフォームはユーザーの定量的利益の5％をプラットフォームの運営資金として抽出します。",
	内容7:'Plus500Ai は現在 USDT の入出金をサポートしていますが、将来的に他のチャネルが開設される場合は公式 Web サイトで発表される予定です。 最低入出金はどちらも 10 USDT で、入金と出金は最速で 5 分以内、最低でも 24 時間以内です。 ユーザーの出金時間に制限はなく、いつでもお金を引き出すことができます。 出金は通常の営業日であれば 5 分から 24 時間以内に行われます。 週末の出金は月曜日に処理されるように手配されます。',
	BSC提现:"USDT_BSC 出金",
	社区Ads收益:"コミュニティ広告収入",
	Ads收益区间:"広告収入 1-1000$",
	申请Ads费用:"アプリ広告料金",
	Ads促销地址:"広告宣伝アドレス",
	填写Ads促销地址:"広告宣伝アドレスを入力",
	申请Ads费用须知:"広告料金のお申し込み手順",
	须知A:"1. ユーザーはプロモーション ビデオを録画し、広告プロモーションのために自分の YouTube、Facebook、チャンネル、またはその他のコミュニティにアップロードし、1 ～ 1000 ドルのボーナスを申請します。プラットフォームは、アプリケーションを送信してから 3 日後に広告ビデオ素材を審査し、広告を掲載します。動画プロモーション効果に応じてアカウントにボーナス アカウントの残高、プロモーション効果のない動画は確認できません",
	须知B:"2. 同一チャンネル内の同一広告プロモーションビデオの特典応募は1回のみ、新規プロモーションビデオ素材の公開は週1回、悪質なリクエストを繰り返し送信しないでください。 悪意を持ってプラットフォーム上で自分以外の広告リクエストを複数回送信したり、審査に失敗した広告素材を送信したりすると、システムが検出した後にアカウントが禁止されます",
	须知C:"3. ユーザーが記録した広告ビデオ素材には、基本的なプラットフォームの紹介とプラットフォームの機能の説明が必要です. ビデオ時間は5分以上. チャンネル登録者が多いほど、ビデオのプロモーション効果が高くなり、申請できる広告費。 具体的な広告料金は、YOUTUBE を例にとると次のとおりです。",
	须知D: "サブスクライバー 0-2K: 申請金額 1-20$",
	须知D1:"サブスクライバー 3K-10K: 申請金額 21-40$",
	须知D2:"サブスクライバー 11K-30K: 申請金額 41-100$",
	须知D3:"サブスクライバー 31K-50K: 申請金額 101-300$",
	须知D4:"サブスクライバー 51K-100K: 申請金額 301-1000$",
	须知D5:"4. システムは毎週日曜日に広告ボーナス申請を審査および処理し、資格のあるユーザーはシステムから送信されたボーナスを受け取ります。",
	请求审核:"審査をリクエスト",
	申请金额:"申込金額",
	URL促销地址:"URL プロモーション アドレス",
	提交时间:"提出時間",
	需要更新的银行卡不存在:"更新する銀行カードが存在しません",
	银行卡提现:"銀行カード現金引き出し",
	预计收入:"推定収入",
	总投资:"総投資額",
	购买价:"購入価格",
	邀请:"招待する",
	成员:"メンバー",
	积分等级折扣规则介绍3:"この割引額は 2023 年 12 月 31 日までのみ有効で、2024 年 1 月 1 日以降は新たな調整が行われます。",
	LV1佣金:"LEVEL1 コミッション",
	LV2佣金:"LEVEL2 コミッション",
	LV3佣金:"LEVEL3 コミッション",
	合格条件:"デポジットは 1 人あたり最低 8 ドル",
	随时可退:"いつでも返金",
	登录密码错误:"ログインパスワードが間違っています",
	充值奖金:"入金 USDT≥500USDT、ボーナス 3%",
	充值奖金1:"入金 IDR≥50USDT、ボーナス 2%",
	充值奖金2:"入金 THB≥50USDT、ボーナス 5%",
	活动已过期:"イベントの有効期限が切れました。次のイベントを待っています",
	不能重复发送短信:"10 分以内にテキスト メッセージを繰り返し送信することはできません。 EMAIL CAPTCHA を使用して直接登録することをお勧めします。",
	限购说明1:"こんにちは。NO.0 クオンツ ロボット取引戦略パッケージの各ユーザーは、1 回のみ購入できます。",
	修改钱包地址提示:"USDT の出金アドレスはバインド後は変更できません。サポートが必要な場合は、カスタマー サービスにお問い合わせください。",
	修改银行提示:"引き落とし銀行口座はバインド後は変更できません。サポートが必要な場合は、カスタマー サービスにお問い合わせください。",
	注册推送消息1:"INGSAI へようこそ。新規ユーザーが POS マイニング ノードを開始すると、5 分ごとに POS マイニング収入を受け取ります。 チームコミッション収入は、1 日あたり約 00.00 時にアカウント残高に自動的に入力されます。",
	限购说明:"このタイプのクオンツ ロボット パッケージの最大購入回数は 10 回です。他のタイプのクオンツ トレーディング パッケージを購入することをお勧めします。",
	注册协议:"(アカウントの作成) を押すと、18 歳以上であることが確認されます。 お客様は、Plus500AI がその製品/サービスに関する関連情報を提供するためにお客様に電子的に連絡するためにお客様の電子メール アドレスまたはその他の個人データを使用する場合があることを承知しています。 また、お客様の取引体験を最大限に活用できるよう、市場イベントについて通知する場合もあります。 プラットフォームの (通知設定) タブと (プライバシー設定) タブで通知設定を管理および変更できます。 これらのコミュニケーションはいつでも購読を解除できます。 詳細については、当社の (プライバシー規約) をご覧ください。",
	公司说明1:"Plus500AIはPlus500 Ltd.の登録商標です。 Plus500 Ltd は以下の子会社を通じて運営されています。",
	公司说明2:"Plus500SEY Ltd は、セーシェル金融サービス庁によって認可および規制されています (ライセンス番号 SD039)。",
	公司说明3:"Plus500SEY Ltd は、このウェブサイトで説明または入手可能な金融商品の発行者および販売者です。",
	公司说明4:"Plus500UK Ltd は金融行動監視機構によって認可され、規制されています。 金融行為監視機構登録番号: 509909。小売ユーザーは暗号通貨 CFD を利用できません。",
	公司说明5:"Plus500CY Ltd は、キプロス証券取引委員会によって認可および規制されています (認可コード: 250/14)。 英国の個人投資家は暗号通貨 CFD を利用できません。",
	公司说明6:"Plus500AU Pty Ltd は、ASIC 発行の AFSL (No. 417727)、ニュージーランド FMA 発行の FSP (No. 486026)、および南アフリカ FSCA 発行の認定金融サービスプロバイダー (No. 47546) のライセンスを保有しています。",
	公司说明7:"Plus500EE AS はエストニア金融監督庁によって認可および規制されています (ライセンス番号 4.1-1/18)。",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) は、シンガポール金融管理局が発行した資本市場サービスライセンスを保有しており、資本市場商品の取引を許可しています (ライセンス番号 CMS100648)。",
	公司说明9:"Plus500AE Ltd は、ドバイ金融サービス局 (F005651) によって認可および規制されています。",
	加入我们的社群:"私たちのコミュニティに参加する",
	PLUS500集团:"PLUS500グループの",
	受监管的操作:"規制された操作",
	健全的全球监管框架:"強固な世界的な規制枠組み",
	治理和社会责任:"ガバナンスと社会的責任",
	底部说明:"Plus500 は、事業のあらゆる側面において責任を持って持続可能な運営を行うことに今後も専念しており、このアプローチは当社の義務であり、効果的な経営の重要な部分であると信じています。 Plus500 は、従業員、顧客、地域社会、慈善団体、そして株主にとって目に見える価値を生み出すために、さまざまな ESG イニシアチブに取り組んでいます。",
	投资者联系方式:"投資家連絡先",
	投资者关系主管:"投資家向け広報担当責任者",
	AI智慧型量化交易:"AIインテリジェント定量取引",
	订单执行快速可靠:"注文執行は迅速かつ信頼性が高い",
	全天候自动分析行情:"24時間自動市場分析",
	交易受监管低风险:"取引は規制されており、リスクは低い",
	稳定策略高效收益:"安定した戦略と効率的な利益",
	取款快速且安全:"素早く安全にお金を引き出す",
	游戏说明:"ゲームに参加してお金を稼ぐために必要な最低残高は 10 USDT です",
}
