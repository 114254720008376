<template>
  <div class="page">
    <div class="header">
		<title>Plus500 WebTrader | Leading CFD Platform | Online Trading </title>
		<div></div>
		<div style="margin-top:10px; height:30px;width:38%; text-align: center;  border-radius: 10px; border: 1px solid #cccccc;background-color:#fff;box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35);">{{
		      $t('语言切换')}}<img class="msg-icon" style="width: 15px;height: 15px;vertical-align:middle; " src="@/assets/lang2.png" alt="" @click="changlang()" /></div>
      </div>
	  <div class="content" style="text-align: center;">
        <img class="logo" src="../../assets/img/logo111.png" alt="" />
      
      <!--<div class="logg">{{ $t('注册') }}</div>-->
      <!--<div></div>
    </div>-->
    
    <!--<div class="content" style="font-size:13px; color:white;text-align: center;">-->
      <van-form>

        <div class="phone" >
          <div style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px; height:1.4rem; width:160px;border-radius: 0.26667rem;padding-left: 8px; background-color:#fff;">
            <fdSelect :list="option1" v-model="selected"></fdSelect>
          </div>
          <!-- <van-field class="input prefix" v-model="prefix" /> -->
          <!-- <van-dropdown-menu >
            <van-dropdown-item v-model="value" :options="option1" />
          </van-dropdown-menu> -->
          <van-field class="input" style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;" v-model="mobile" :placeholder="$t('输入手机号')" />
		  
		  
        </div>
		<!--<div class="captcha" v-show="smswithc">
		  <van-field v-model="captcha1" :placeholder="$t('输入手机验证码')" />
		  <van-button class="textBtn" plain type="info" @click="getcode1()">
		    <span v-show="show1">OTP</span>
		    <span v-show="!show1">{{ count1 }} s</span>
		  </van-button>
		</div> -->
        <van-field class="input" style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;" v-model="username" :placeholder="$t('输入用户名')" />
         
		 <!--<div class="captcha" v-show="smswithc">
          <van-field style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;" v-model="captcha" :placeholder="$t('输入验证码')" />
		  
          <van-button class="textBtn" plain type="info" @click="getcode()">
		  
            <span v-show="show">{{ tishi }}</span>
            <span v-show="!show">{{ count }} s</span>
          </van-button>
        </div>-->
        <van-field class="input" style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;" v-model="password" type="password" :placeholder="$t('输入密码')" />
		
        <van-field class="input" style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;" v-model="password2" type="password" :placeholder="$t('再次输入密码')" />
		
        <van-field class="input" style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;" v-model="invit" :placeholder="$t('请输入邀请码')" />
		
      </van-form>
    </div>
    <ROBOT />
    <div style="width:95%;margin-left:10px; text-align: center;">
      <van-button class="btn" round block type="info" @click="register">{{
        $t('注册')
      }}</van-button>
    </div>
    <br>
    <div class="signUp" style="text-align: center;font-size: 15px;">
      <router-link to="/login"><span  style="text-decoration:underline;"> {{ $t('立即登录') }}</span></router-link>
    </div>
	<div class="center">
	  <p  style="background:white;text-align:center;font-weight:bold;">{{ $t('加入我们的社群') }}:</p>
	</div>
	<div class="signUp">
		 <img @click="See('https://youtu.be/gexuEJyHd9E')" src="../../assets/youtube.png" style="width:40px;height: 40px;"/>
	       <img @click="See('https://www.facebook.com/profile.php?id=61555870555449&mibextid=ZbWKwL')" src="../../assets/fb.png" style="width:40px;height: 40px;"/>
		    <img @click="See('https://www.instagram.com/ingsaimining')" src="../../assets/ins.png" style="width:40px;height: 40px;"/>
			<img @click="See('https://x.com/Ingsaimining?t=ycFsFfHmgRhDjWxl26UKVA&s=09')" src="../../assets/twitter.png" style="width:40px;height: 40px;"/>
			<!--<img @click="See('https://chat.whatsapp.com/HWVOY0fXTXOLXiKq1l8dZv')" src="../../assets/ws.png" style="width:40px;height: 40px;"/>-->
			<img @click="See('https://t.me/plus500ai_offical')" src="../../assets/tg.png" style="width:40px;height: 40px;"/><br><br><br><br><br>
			<!--<img @click="See('https://line.me/ti/p/L_d88h7cvc')" src="../../assets/line.png" style="width:40px;height: 40px;"/>-->
			<!--<img @click="See('https://youtu.be/gexuEJyHd9E')" src="../../assets/qa.gif" style="width:100%;"/>-->
	   </div>
		<div class="center" style="margin-top:40px;text-align:center;color:#666">
		<p  style="background:#ffffff;width:100%;color:#1A1A1A">{{ $t('注册协议') }}</p></br>	
		<p  style="background:#ffffff;width:100%;">{{ $t('公司说明1') }}</p></br>
		<p  style="background:#ffffff;width:100%;">{{ $t('公司说明2') }}</p></br>
		<p  style="background:#ffffff;width:100%;">{{ $t('公司说明3') }}</p><br>
		<p  style="background:#ffffff;width:100%;">{{ $t('公司说明4') }}</p><br>
		<p  style="background:#ffffff;width:100%;">{{ $t('公司说明5') }}</p><br>
		<p  style="background:#ffffff;width:100%;">{{ $t('公司说明6') }}</p><br>
		<p  style="background:#ffffff;width:100%;">{{ $t('公司说明7') }}</p><br>
		<p  style="background:#ffffff;width:100%;">{{ $t('公司说明8') }}</p><br>
		<p  style="background:#ffffff;width:100%;">{{ $t('公司说明9') }}</p>
		</div>
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import { Button, NavBar, Field, DropdownMenu, DropdownItem, Toast } from 'vant'
import { apiRegister, getUrlKey, apiRegss, apismssWitch, apiLogin, apigetGuoma } from '@/http/api/' // 导入我们的api接口
import fdSelect from "@/components/fd-select/fd-select.vue";

export default {
  name: 'welcome',
  components: {
    ROBOT,
    fdSelect,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  data () {
    return {
      // prefix: '+234',
      value: '+234',
      mobile: '',
      password: '',
      password2: '',
      captcha: '',
	   captcha1: '',
      invit: '',
      username: '',
      smswithc: false,
      option1: [

      ],
      count: 60,
	  count1: 60,
      show: true,
	   show1: true,
      timer: null,
      tishi: this.$t('OTP'),
      selected: {}, //这是拿到的数据
    }
  },

  watch: {
    mobile: function () {
      this.mobile = this.mobile.replace(/[^\d]/g, '')
    }
  },
  mounted () {
    apismssWitch({}).then((res) => {
      if (res.info.sta_tankuang == '1') {
        this.smswithc = true;
      } else {
        this.smswithc = true;
      }

    })
  },
  created (e) {
    var url = window.location.href
    let code = getUrlKey('code', url)
    this.invit = code;
    apigetGuoma({

    }).then((res) => {
      var that = this;
      console.log(res);
      var arr = res.info;
      for (let i = 0; i < arr.length; i++) {
        let arr2 = new Array();
        arr2.text = arr[i].code;
        arr2.value = arr[i].code
        this.option1.push(arr2);
        console.log(this.option1)

      }
  

      that.value = arr[0].code;
      this.option1=this.option1.map((v,index)=>{
        return {
          code:v.text,
          img:require(`@/static/images/country/${v.text.replace(/\+/g,'')}.png`),
          id:index+''
        }
      })
    this.selected={
      ...this.option1[0]
    }
    console.log(this.selected,9999)

    });
  },
  methods: {
    isEmail (str) {
      return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(str);
    },
	changlang () {
	  this.$router.push('/lang')
	},
    getCode2 () {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
	getCode21 () {
	  const TIME_COUNT = 60
	  if (!this.timer) {
	    this.count1 = TIME_COUNT
	    this.show1 = false
	    this.timer = setInterval(() => {
	      if (this.count1 > 0 && this.count1 <= TIME_COUNT) {
	        this.count1--
	      } else {
	        this.show1 = true
	        clearInterval(this.timer)
	        this.timer = null
	      }
	    }, 1000)
	  }
	},
    getcode () {
		console.log(this.selected,888888)
      if (!this.mobile) {
        Toast.fail(this.$t('手机号码不能为空'))
        return
      }
      if (!this.username) {
        Toast.fail("Email address cannot be empty")
        return
      }

      Toast.loading({
        duration: 0,
        message: this.$t('加载中'),
        forbidClick: true
      })
      apiRegss({
        username: this.username,
        mobile: this.mobile,
        type: 'reg',
        optionval: this.selected.code
      }).then((res) => {
        Toast.clear()
        console.log(res)
        if (res.status) {
          Toast.success(this.$t(res.info))
          this.getCode2()
        } else {
          Toast.fail(this.$t(res.info))
        }
      })
    },
	
	
	getcode1() {
		console.log(this.selected,888888)
	  if (!this.mobile) {
	    Toast.fail(this.$t('手机号码不能为空'))
	    return
	  }
	 
	
	  Toast.loading({
	    duration: 0,
	    message: this.$t('加载中'),
	    forbidClick: true
	  })
	  apiRegss({
	     
	    mobile: this.mobile,
	    type: 'reg1',
	    optionval: this.selected.code
	  }).then((res) => {
	    Toast.clear()
	    console.log(res)
	    if (res.status) {
	      Toast.success(this.$t(res.info))
	      this.getCode21()
	    } else {
	      Toast.fail(this.$t(res.info))
	    }
	  })
	},
    register () {
		console.log(this.selected,55555);
		
		
		
		if(!this.captcha){
			
			// if(!this.captcha1){
			// 	  Toast.fail("Please enter the verification code");
				
			// 	return false;
				
			// }
			
			
		}
      let form = {
        invit: this.invit,
        mobile: this.mobile,
        password: this.password,
        password2: this.password2,
        username: this.username,
     //   code: this.captcha,
		 shou_code: this.captcha1,
        optionval:this.selected.code
      }
      Toast.loading({
        duration: 0,
        message: this.$t('加载中'),
        forbidClick: true
      })
      apiRegister(form).then((res) => {
        console.log(res)
        if (res.status == 1) {
          Toast.clear()
          setTimeout(() => {
            // 存储用户登录状态
            Toast.success(this.$t('注册成功'))
            apiLogin({
              mobile: this.mobile,
              password: this.password,
              optionval:this.selected.code
            }).then((res) => {
              localStorage.setItem('key', res.userid)
              localStorage.setItem('msg', 1)

              this.$router.replace('/')
            })

          }, 1500)
        } else {
          Toast.fail(this.$t(res.info))
        }
      })
    },

    captchaEvent () {
      this.textContent = '12miao'
    },
    getBack () {
      this.$router.back()
    },
    onSubmit () {
      console.log('zhuce ')
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .van-dropdown-menu__bar {
    background-color: #2b2b2b !important;
  }
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    .close {
      width: 28px;
      height: 28px;
    }
    .img {
      width: 40px;
	  margin-top: 10px; 
    }
	
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: bold;
      color: #131587;
    }
  }

  .van-field {
    background-color: #fff !important;
  }
  .van-field__control {
    background-color: #fff !important;
  }
  .van-dropdown-menu .van-dropdown-menu__bar {
    background-color: #fff !important;
  }
  .btn {
  //  box-shadow: 0px 10px 20px #0d9135;
    font-size: 40px;
    font-weight: bold;
    height: 103px;
    background: #0c2780;
	border-radius: 5px;
    border: none;
    color: #fff;
  }
  .signUp {
    //   color: #fff;
    text-align: center;
    // width: 80%;
    // position: absolute;
    // bottom: 90px;
    // left: 50%;
    // margin-left: -40%;
    height: 30px;
    margin-top: 45px;
    font-size: 30px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #131587;
  }
  .phone {
    display: flex;
  }
  .bt {
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20000000298023224);
  }
  .captcha {
    height: 90px;
    padding: 10px;
    margin-bottom: 30px;
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.20000000298023224);
    border-radius: 20px;
    .textBtn {
      width: 500px !important;
    }

    ::v-deep .van-button--plain.van-button--info {
      color: #FFFFFF;
    }
  }
  .content {
    //margin-top: 90px;
    padding: 0 36px;
	.logo {
	  width: 724px;
	  height: 286px;
	 // margin-top: 53px;
	 // margin-bottom: 5px;
	}
    .prefix {
      width: 180px;
      margin-right: 30px;
      ::v-deep input.van-field__control {
        color: #FFFFFF;
      }
    }
  }
  ::v-deep input.van-field__control {
    color: #000;
  }
  ::v-deep .van-button--plain {
    background-color: #131587;
  }
  ::v-deep.van-button--info {
    border: none;
  }
  ::v-deep .van-dropdown-menu__bar {
    height: 80px;
  }
}
</style>
