export default {
  tabbar: {
    首页: 'Əsas səhifə',
    市场: 'Bazar',
    任务: '任务',
    团队: 'Komanda',
    我的: 'Mənim'
  },
  navbar: {
    充值: 'Əmanət',
    提现: 'Pul çıxarın',
    帮助: 'FAQ',
    分享: 'Dəvət et',
	推广: 'Reklam gəliri',
    活动: 'Mükafat',
    游戏: 'Oyun',
  },	 
  payment: {
    付款方式详情: 'Ödəniş metodu təfərrüatları',
    银行卡: 'Bank kartı',
    钱包地址: 'Çıxarma üsulu',
    交易密码: 'Tranzaksiya parolu',
    保存: 'Yadda saxlae'
  },
  market: {
    市场: 'Bazar',
    名称: 'Adı',
    价格: 'Qiymət',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: 'Mən görürəm'
  },
  邀请好友注册领取佣金:"Dostlarınızı qeydiyyatdan keçməyə və komissiya almağa dəvət edin",
  领取:"Qəbul edin",
  未满足条件:"Şərt yerinə yetirilməyib",
  总收入:"Toplam gəlir",
  投资建议:"※Daha əhəmiyyətli gəlir əldə etmək üçün mürəkkəb faizli investisiya planı həyata keçirin",
  语言切换: 'Dil',
  POS区块节点挖矿: 'Ai Kəmiyyət Ticarət Detalları',
  节点类型: 'Robot\nNöv',
  加入价格: 'Alın\nQiymət',
  质押资金: 'POS\nfondlar',
  质押周期: 'Dövr\n(gün)',
  每日收入: 'Gündəlik\nGəlir',
  累计积分: 'Yığılmış\nXallar',
  代理等级: 'Agent\nSəviyyə',
  下级折扣比: 'Subordinate\nEndirim',
  代理折扣比: 'Agent\nEndirim',
  下属等级: 'Subordinate\nSəviyyə',
  代理佣金比率: 'Agent\nKomissiya\nQiymət',
  下属每日收入: 'Subordinate\nGündəlik\nMənfəət',
  代理佣金: 'Agent\nKomissiya',
  引用对象: 'İstinad\nobyekt',
  积分等级折扣规则: 'Bal səviyyəsi endirim qaydaları',
  积分等级折扣规则介绍: 'Ai kəmiyyət ticarətində uğurla iştirak edən istifadəçilər müvafiq hesab xalları əldə edəcəklər və onların agent üstün istifadəçiləri də müvafiq xalları artıra bilərlər (birbaşa tabeçiliyində olanların 15%-nə şamil olunur).Cəmi hesab xalları müvafiq şərtlərə cavab verdikdə, agent səviyyəsi hesab təkmilləşdiriləcək. , Müxtəlif səviyyələrdə olan agent hesabları fərqli nisbətlərdə qiymət endirimləri əldə edə bilər. Müvafiq nöqtə endirimlərinin təfərrüatları aşağıdakılardır:',
  积分等级折扣规则介绍2: 'Fərqli əlaqələri (birbaşa) olan istifadəçilər tabeçiliyində olanların Ai kəmiyyət əməliyyatlarında iştirakına nisbət əsasında endirimlər və agent səviyyəsində endirimlər alacaqlar. Digər yüksək səviyyəli rəhbərlər də öz agentlərinə nisbətdə endirim səviyyələri alacaqlar. Ai kəmiyyət əməliyyatlarında iştirak etmək üçün qiymət endirimləri: Müvafiq agent səviyyələri olan istifadəçilər Ai kəmiyyət əməliyyatlarında iştirak edərkən müvafiq agent səviyyəli üstünlük hüquqları əldə edəcəklər.',
  团队: 'Komanda',
  代理佣金制度: 'Agentlik komissiya sistemi',
  代理佣金说明详情: 'A tərəfindən əldə edilən LV1 agentlərinin nisbəti: 8%, A tərəfindən əldə edilən LV2 agentlərinin nisbəti: 3%, A tərəfindən əldə edilən LV3 agentlərinin nisbəti: 2%. Misal: B, C və D-nin gündəlik mənfəəti: 100 ABŞ dolları və A tərəfindən əldə edilən LV1 agent mükafatı: 8%*100=8 ABŞ dollarıdır. A tərəfindən əldə edilən LV2 agent mükafatı: 3%*100=3 ABŞ dolları. A tərəfindən əldə edilən LV3 agent mükafatı: 2%*100=2 ABŞ dolları. Ümumi komissiya gəliri: 8+3+2=13 USD.',
  代理结构图示: 'Proksi struktur diaqramı',
  代理结构图示说明: 'A sizi təmsil edir, A B (A tabeliyində olan B), B C (B tabeliyində olan C), C isə D (C tabeliyində olan D) dəvət edir. A çoxlu tabeliyində ola bilər (B1, B2, B3, B4, B5, B6 və s.), B (B1, B2, B3) də çoxlu tabeliyində ola bilər (C1, c2, c3, c4, c5, c6 və s.). ) C... D...',
  高级代理佣金说明: 'Baş Agent Komissiyasının Təlimatları',
  高级代理佣金说明详情: 'A sizi təmsil edir, A B-ni dəvət edir (A tabeliyində olan B), B C-ni dəvət edir (B-nin tabeliyində olan C), C isə D (C tabeliyində olan D). Üstün agent öz hesabında və tabeliyində olan hesablarda iştirak edən node mədən işlərinin ümumi məbləğinin nisbətinə uyğun olaraq müxtəlif agentlik komissiya gəliri (%) əldə edəcək. A, agentlik mükafatlarının müvafiq nisbətini əldə etmək üçün tabeliyində olan üzvlərin gündəlik gəlirlərini müqayisə edir. İştirak edən qovşaqlar işlək vəziyyətdə olmalıdır. Agentlik gəliri bütün maliyyə xərcləri üçün INGSAI MINING tərəfindən təmin edilir və normal tabe üzvlərinin gündəlik gəlirinə təsir göstərməyəcək.',
  高级代理佣金说明详情2: 'Nümunə: Böyük agentlərin ümumi mədən məbləği (A) aşağı üzvlərin ümumi mədən miqdarının 30%-i (B/C/D) (Məsələn: B/C/D-nin A>30%-i)',
  积分: 'Xallar',
  规则: 'Qayda',
  会员级别: 'Üzvlük Səviyyəsi',
  经验: 'Ümumi xal',
  代理信息: 'Üzvlük məlumatları',
  更多: 'Ətraflı',
  团队奖励: 'Komanda komissiyası',
  昨天: 'Dünən',
  本星期: 'Bu həftə',
  全部: 'Hamısı',
  机器人说明: "Plus500Ai istifadəçiləri eyni vaxtda bir neçə kəmiyyət ticarət paketi ala bilər və NO.1 robotu hər hesaba yalnız 10 dəfə alış edə bilər. Unutmayın: Kəmiyyət robotunu aldıqdan sonra strategiyanı aktivləşdirmək üçün klik etməyi unutmayın, əks halda gəlir olmayacaq. Kəmiyyət robotunun etibarlılıq müddəti satınalma vaxtına əsasən hesablanır. Zəhmət olmasa, kəmiyyət ticarət strategiyasını vaxtında aktivləşdirin. Əgər kəmiyyət robotu müddəti bitdikdən sonra aktivləşdirilməyibsə, kəmiyyət ticarət strategiyası paketinin alınması xərclərini itirəcəksiniz! Plus500Ai kəmiyyət ticarətində iştirak edən bütün istifadəçilər lütfən diqqətlə oxuyun.Bu reqlament! !",
  会员体验: 'Üzv təcrübəsi',
  机器人采购: 'Robot alışı',
  机器人启动押金: 'Node Stake',
  机器人礼物: 'Node hədiyyə',
  机器人订单: 'Node sifarişi',
  一次性总和: 'Göstərmək',
  总计: 'Cəmi',
  
  被激活: 'Artıq POS',
  工作中: 'AKTİV OLUNUB',
  暂停: 'POS-u PAUZA',
  结束: 'MÜDDƏTİ KEÇİB',
  刷新: 'Təzələmək',
  来源: 'Mənbə',
  价格: 'Robot paket qiyməti',
  启动押金: 'POS məbləği',
  剩余时间: 'Vaxt qalıb',
  激活时间: 'Aktivasiya vaxtı',
  号: 'NO',
  我的资产: 'Aktivlərim',
  退出: 'Hesabdan çıxın',
  邀请码: 'Dəvət kodu',
  我的钱包余额: 'Pul kisəsi balansım',
  我的机器人仓库: 'Mənim Robot Siyahım',
  收益面板: 'Qazanc paneli',
  去提款: 'Pul çıxarın',
  昨天代理返利: 'Agentlik komissiyası dünən',
  累计代理返利: 'Məcmu agentlik komissiyası',
  今天机器人收益: 'Bugünkü ticarət qazancı',
  累计机器人收益: 'Kumulyativ ticarət gəliri',
  累计机器人回扣: 'Yığılmış ticarət komissiyası',
  累计总回报: 'Məcmu ümumi gəlir',
  自动订单机器人数量: 'Aktivləşdirilmiş kəmiyyət ticarət robotu',
  我的付款方式: 'Ödəniş üsulum',
  个人收入记录: 'Şəxsi gəlir',
  团队收入记录: 'Komandanın gəliri',
  记录详情:'Təfərrüatları qeyd edin',
  充值记录: 'Depozit və geri çəkilmə qeydləri',
  我的团队成员: 'Komanda üzvlərim',
  活动中心: 'Qaydanın təqdimatı',
  修改登录密码: 'Giriş parol ayarı',
  交易密码管理: 'Tranzaksiya parolunun qəbulu',
  机器人: 'Ai Ticarət Strategiyası',
  机器人性能: 'Robot Paneli',
  机器人价格: 'Ticarət Strategiyası Qiyməti',
  有效期: 'Keçərlilik müddəti',
  钱包地址: 'Çıxarma üsulu',
  天: 'Gün',
  去购买: 'Alın',
  买个机器人: 'Quant Ticarət Robotunu alın',
  我的钱包余额: 'Hesab balansım',
  预计日收益: 'Gündəlik təxmini gəlir',
  启动保证金: 'Başlanğıc depoziti',
  请输入购买数量: 'Satınalma miqdarını daxil edin',
  个机器人: 'Quant ticarət robotu',
  机器人购买后需要激活才能工作: 'Bir node satın alındıqdan sonra, faydalanmağa başlamaq üçün müvafiq POS miqdarı tələb olunur',
  机器人激活需要从余额中扣除相应的启动押金:
    'Node POS mining, hesab balansından müvafiq girov məbləğini çıxarmalıdır',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    'Node POS mining dayandırıldıqda dərhal geri qaytarıla bilən POS miqdarı',
  购买: 'ALIN',
  点击去了解我们: 'Kəmiyyətli Ticarət',
  购买机器人: 'Bir robot al',
  快速入口: 'Sürətli giriş',
  '日/个人受益': 'Günlük/şəxsi fayda',
  购买教程: 'AI Ticarət Robotunun Siyahısı',
  激活金额: 'POS ÜCRETİ',
  订单数量: 'Sifarişin miqdarı',
  我们的合作伙伴: 'Kripto bazarı tərəfdaşlarımız',
  提款: 'Pul çıxarmaq',
  提款金额: 'Çıxarma məbləği',
  你还没有添加银行卡: 'Siz bank kartı əlavə etməmisiniz',
  提现说明: 'Çıxarma təlimatları',
  说明1: '1.Bir kliklə seçə və ya doldurma məbləğini əl ilə daxil edə bilərsiniz',
  说明2: '2. USDT (TRC20) istifadə etmək tövsiyə olunur, transfer haqqı ən aşağıdır',
  说明3: '3.Müştəri xidməti saatları bazar ertəsindən cüməyə AM8-dən PM6-ya qədər Mərkəzi Amerika vaxtıdır',
  说明4: 'Doldurmaq üçün USDT istifadə edən istifadəçilər uğurlu köçürmə və TXID-nin düzgün skrinşotunu yükləməlidirlər və hesab platforma baxışdan keçdikdən sonra uğurla alınacaq.',
  说明5: '4. Minimum doldurma 8 USDT təşkil edir ki, bu da sistemin hesabı tez bir zamanda nəzərdən keçirməsi və emal etməsi üçün əlverişlidir.',
  说明6: '5. Doldurma ilə bağlı hər hansı sualınız varsa, kömək üçün vaxtında onlayn müştəri xidməti ilə əlaqə saxlayın',
  确认提款: 'Geri çəkilməyi təsdiqləyin ',
  请输入手机号码:'Telefon nömrəsi',
  请输入提现金额: 'Çıxarma məbləği ',
  提现费:'Çıxarma haqqı',
  提现须知: 'Lütfən, pul çıxarma qaydalarını diqqətlə oxuyun',
  TIPS1: '1: Gündəlik çəkilmənin nəzərdən keçirilmə vaxtı bazar ertəsindən cümə gününə qədər AM8-dən PM6-a qədərdir. Çıxarış həftə sonları edilə bilər və geri çəkilmənin nəzərdən keçirilmə vaxtı bazar ertəsi günüdür',
  TIPS2: '2:7X24 saat sistemi, istənilən vaxt pul çıxarın, ən geci 24 saat ərzində hesaba çatın və ən qısa müddətdə 5 dəqiqə ərzində hesaba çatın.',
  TIPS3: '3: Onlayn müştəri xidməti vaxtı: Bazar ertəsindən Cümə AM8-dən PM6-a qədər',
  TIPS4: '4: USDT köçürməsinin dəyərinə görə, istifadəçi pul çıxardıqda platforma pul götürmək üçün komissiya alır.',
  TIPS5: '5: Minimum pul çıxarma məbləği 10 USDT-dir',
  钱包余额: 'Pul kisəsi balansı',
  输入数量:"Kəmiyyəti daxil edin",
  数量:"Kəmiyyət",
  手机号码:"Telefon nömrəsi",
  手机号码已存在:"Mobil telefon nömrəsi qeydə alınıb",
  邮箱:"E-poçt",
  邮箱不能为空:"E-poçt boş ola bilməz",
  输入手机号码: 'Mobil nömrəni daxil edin',
  请输入登录密码: 'Giriş parolu',
  登录: 'Daxil ol',
  输入账号: 'Hesab nömrəsini daxil edin',
  密码: 'Şifrə',
  点击注册: 'Hesabınız yoxdur？İndi yaratın!',
  忘记密码: 'Şifrəni unut',
  重新登录: 'Yenidən giriş',
  密码找回成功: 'Parol uğurla əldə edildi',
  找回成功请重新登录: 'Uğurla bərpa edin, yenidən daxil olun',
  确认: 'Təsdiq edin',
  发送验证码: 'Təsdiq kodunu göndər',
  再次输入密码: 'Şifrəni təsdiqləyin',
  输入验证码: 'Təsdiq kodu daxil edin',
  输入手机验证码:"Mobil OTP daxil edin",
  输入手机号: 'Telefon nömrəsini daxil edin',
  输入密码: 'Giriş parolu',
  快速注册: 'Tez qeydiyyat',
  注册: 'Qeydiyyatdan keçin',
  立即登录: 'Artıq hesabınız var, daxil olmaq üçün klikləyin',
  请输入邮箱: 'E-poçt e-poçt ünvanınızı daxil edin',
  输入用户名: 'İstifadəçi adınızı daxil edin',
  请输入邀请码: 'İstinad kodunu daxil edin',
  返回: 'Qayıt',
  密码找回失败: 'Parolun bərpası uğursuz oldu',
  输入的信息有误: 'Daxil edilən məlumatlar yanlışdır',
	获取验证码:"OTP",
	手机号码不能为空:"Telefon nömrəsi boş ola bilməz",
	账号不存在:"Hesab mövcud deyil",
	发送成功:"Uğurla göndərildi",
	今日收益:"(cari gəlir)",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"Salam, POS-u yarı yolda dayandırsanız, sistem 5% POS haqqı alacaq. Xüsusi xatırlatma: Təcrübənin pulsuz qovşağı dayandırıldıqdan sonra onu yenidən əldə edə bilməyəcəksiniz və heç bir ödəniş ala bilməyəcəksiniz! !",
	我同意:"mən razıyam",
	用户账号ID:"İstifadəçi ID",
	取消:"Ləğv et",
	日收益:"Gündəlik qazanc",
	请输入购买数量:"Satınalma miqdarını daxil edin",
	数量不能为空:"Kəmiyyət boş ola bilməz",
	加载中:"Yüklənir...",
	唯一码:"Robot Kodlaşdırma",
	未激活:"AKTİVDİR",
	激活:"Strategiyaya başlayın",
	购买时间:"Alış vaxtı",
	"钱包余额不足，激活该机器人失败":"Qeyri-kafi hesab balansı, node POS mining həyata keçirilə bilməz",
	
	运行时长:"İş vaxtı",
	签约购买:"Balans alışı",
	系统赠送:"Sistem hədiyyəsi",
	状态:"Dövlət",
	正常:"Normal",
	我的机器人:"Kvant Ticarət Robotum",
	一级会员:"Səviyyə 1",
	二级会员:"Səviyyə 2",
	三级会员:"Səviyyə 3",
	人:"",
	充值客服:"Müştəri xidmətlərini doldurun",
	充值:"Əmanət",
	提现:"Pul çıxarmaq",
	提款密码:"Çıxarma şifrəsi",
	设置交易密码:"Əməliyyat parolunu təyin edin",
	登录密码:"Giriş parolu",
	请输入交易密码:"Əməliyyat parolunu daxil edin",
	再次请输入交易密码:"Əməliyyat parolunu təsdiqləyin",
	确认: 'Təsdiq edin',
	手机号码不能为空:"Telefon nömrəsi boş ola bilməz",
	两次密码不一致:"İki parol uyğun gəlmir",
	请输入验证码:"E-poçt OTP daxil edin",
	操作成功:"Uğurlu əməliyyat",
	"用户名或密码不正确,登录失败":"İstifadəçi adı və ya parol səhvdir, giriş uğursuz oldu",
	登录成功:"Giriş uğurlu oldu",
	充值说明:"Depozit təlimatları",
	请先设置支付密码:"Əvvəlcə əməliyyat parolunu təyin edin",
	复制成功:"Uğurla kopyalayın",
	冻结机器人做单本金:"Kəmiyyət fondlarını dondurun",
	待审核:"Baxış gözlənilir...",
	成功:"Müvəffəqiyyət",
	失败:"Uğursuzluq",
	审核中:"Nəzərdən keçirilir",
	在线充值:"Onlayn əmanət",
	描叙:"Təsvir etmək",
	POS节点挖矿LV0:"POS-NO.0 node mədən",
	POS节点挖矿LV1:"POS-NO.1 node mədən",
	POS节点挖矿LV2:"POS-NO.2 node mədən",
	POS节点挖矿LV3:"POS-NO.3 node mədən",
	POS节点挖矿LV4:"POS-NO.4 node mədən",
	POS节点挖矿LV5:"POS-NO.5 node mədən",
	银行卡提现:"Bank hesabının çıxarılması",
	USDT提现:"USDT-nin çıxarılması",
	三级代理:"Üçüncü səviyyəli agent",
	一级代理:"Birinci səviyyəli agent",
	二级代理:"İkinci səviyyəli agent",
	一级:"SƏVİYYƏ 1",
	二级:"SƏVİYYƏ 2",
	三级:"SƏVİYYƏ 3",
	做单:"Node mədən",
	登录失败:"Giriş uğursuz oldu",
	请勿重复操作:"Tez-tez əməliyyatları təkrarlamayın",
	邀请码不正确:"Dəvət kodu yanlışdır",
	团队返佣:"Komanda komissiyası",
	购买机器人返佣:"Robot Komissiyası",
	本金返还:"POS qaytarılması",
	佣金收入:"Komissiya gəliri",
	时间:"Vaxt",
	机器人做单返还:"POS məbləği geri qaytarılır",
	涨幅:"Dəyişmək",
	市场:"Bazar",
	客服:"Xidmət",
	验证码错误:"Doğrulama kodu xətası",
  付款方式:"Çıxarma üsulu",
	我的留言:"Mənim mesajım",
  暂无数据: 'Məlumat yoxdur',
  银行卡:'Bank hesabı',
  提款方式:"Çıxarma metodu",
  修改: 'Dəyişdirin',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'Zəhmət olmasa pul kisəsi ünvanınızın düzgün olduğundan əmin olun. Yanlış ünvan daxil etsəniz və pulu ala bilmirsinizsə, kömək üçün vaxtında onlayn müştəri xidməti ilə əlaqə saxlayın',
 连接借记卡:"连接借记卡",
 银行名称:'Bank adı',
 姓名姓氏: 'Sənin adın',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* Vacib qeyd: Bank hesabı məlumatı doğru və pulu çıxarmaq üçün etibarlı olmalıdır.',
 帮助中心:'Yardım mərkəzi',
 会员须知:'Üzv bildirişi',
 '24小时内使用USDT实时取款（推荐）':'1. 24小时内使用USDT实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'Mən görürəm',
 链接点击:'Referans qeydiyyat linki',
 确定:'Əlbəttə',
 建立自己的团队邀请:'Öz komandanızı yaradın',
 此账号已冻结:"Bu hesab dondurulub",
 手机号存已存在:"Mobil telefon nömrəsi artıq mövcuddur",
 注册账号已存在:"Qeydiyyatdan keçmiş hesab artıq mövcuddur",
 请确定新密码:"Yeni parolu təsdiqləyin",
 请再次输入登录密码:"Giriş parolunu yenidən daxil edin",
 密码长度不能少于6位:"Parolun uzunluğu 6 simvoldan az ola bilməz",
 加入:'qoşul',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "The node you sold has been accepted by the other party, and the proceeds from the sale have been transferred to your account, please check。",
 	"你出售的机器人对方已拒绝接受":"The node you sold has been rejected by the other party",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"Bank kartı bağlanıb",
 	USDT_TRC20提现:'USDT_TRC20 çıxarılması',
 	USDT_ERC20提现:'USDT_ERC20 çıxarılması',
 	银行卡提现:'Bank kartının çıxarılması',
 	邀请赠送:'Dəvət bonusu',
 	机器人返佣:'Robot Komissiyası',
 	升级:' uğurlu alış ',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "You are restricted from selling nodes, please contact customer service for details",
   交易编号: "Əməliyyat nömrəsi",
   消息详情:"Mesaj təfərrüatları",
   个人收益累计:"Yığılmış şəxsi gəlir",
   今日代理收益:"Bu gün agentlik gəliri",
   代理累计收益:"Agentlik məcmu gəliri",
   截图保存推荐给朋友:"Referal qeydiyyat linkinizi paylaşın və komanda gəlir komissiyası mükafatlarını qazanmaq üçün dostlarınızı Plus500Ai platformasına qoşulmağa dəvət edin. Üçüncü səviyyəli komanda üzvləri üçün gəlir komissiyası 5%-3%-2% təşkil edir. Komanda komissiyası aşağı səviyyəli üzvlərin gündəlik gəlir məlumatlarına əsaslanır və hər gün saat 00:00-dan sonra avtomatik olaraq hesablanacaq və hesabınıza daxil ediləcək.",
   复制:"Kopiya",
   充值提示1:"Tip 1: The minimum recharge is 10 USDT, you can recharge, otherwise you will not be credited",
   充值提示2:"Tip 2: Recharge needs to be confirmed on the chain. After successful recharge, it is expected to arrive in the wallet balance in about one minute。",
   请输入正确的充值金额:"Depozit məbləği ≥ 8 USDT",
   推荐:"tövsiyə edin",
   充值金额:"Əmanətin məbləği",
   请上传付款截图:"Uğurlu ödənişin ekran görüntüsünü yükləyin",
   交易号: 'Tranzaksiya Hash nömrəsi',
   充值ID提示:"Lütfən, Txid əməliyyatınızın seriya nömrəsini yerləşdirin",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Zəhmət olmasa pul kisəsi ünvanının düzgün olduğundan əmin olun. Pul kisəsinin ünvanı səhv doldurularsa və pulu ala bilməməkdən yaranan itki istifadəçi tərəfindən ödənilir.",
	上传凭证:"Ödəniş sübutunu yükləyin",
	充值确认: "Təqdim et",
	不能为空:"Boş ola bilməz",
  绑定钱包地址:'Cüzdan ünvanını bağlayın',
	备注:"Qeyd",
	银行:"Bank",
	请输入银行卡号:"Zəhmət olmasa bank hesabı nömrəsini daxil edin",
	请输入你的名字:"Zəhmət olmasa adınızı daxil edin",
	银行卡号:"Bank hesab nömrəsi",
	添加银行卡:"Bank hesabı əlavə edin",
	请选择银行:"Bank seçin",
	请输入钱包地址:"Zəhmət olmasa cüzdan ünvanını daxil edin",
	钱包地址:"Pul kisəsi ünvanı",
	"密码错误次数过多,请等待x秒后再试":"Həddindən artıq səhv parol, lütfən, 600 saniyə gözləyin və yenidən cəhd edin",
	"此账号已冻结,请联系客服":"Bu hesab dondurulub, lütfən, müştəri xidməti ilə əlaqə saxlayın",
	手机号格式错误:"Səhv telefon nömrəsi",
	"取款须知":"Geri çəkilmə bildirişi",
	"须知1":"1. 24 saat ərzində real vaxtda pul çıxarmaq üçün USDT-TRC20 istifadə edin (tövsiyə olunur)",
	"须知2":"2. Şənbə və bazar günləri geri çəkilmə",
	"须知3":"*İstifadəçilər şənbə və bazar günləri pul çıxara bilərlər",
	"须知4":"*Həftə sonu pul vəsaitlərinin çıxarılması bazar ertəsi saat 10:00-dan 20:00-dək gələcək",
	我知道了:"Mən görürəm",
	"提现0":"1USDT=1USD ERC20",
	"提现1":"Minimum birdəfəlik pul çıxarma 100 USDT/gün, tək əməliyyat haqqı isə 5 USDT/zamandır.",
	"提现2":"TRC20 üçün minimum pul çıxarma 10 USDT, əməliyyat haqqı isə 1 USDT-dir.",
	"提现3":"Bank hesabından minimum birdəfəlik pul çıxarma $5, əməliyyat haqqı isə əməliyyat məbləğinin $5%-dir.",
	"提现4":"Çıxarma vaxtı: Çıxarma ərizəsi təqdim edildikdən sonra 24 saat ərzində qəbul ediləcək.",
	
	已绑定:"Bağlanmış",
	去设置:"Parametrlərə keçin",
	注册成功:"Qeydiyyat müvəffəqiyyəti",
	汇率:"Valyuta məzənnəsi",
	请输入正确的提现金额:"Zəhmət olmasa düzgün çəkilmə məbləğini daxil edin",
	提款记录:"Çıxarma tarixçəsi",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"Zəhmət olmasa əvvəlcə pul kisəsinin ünvanını bağlayın",
	输入邮箱:"E-poçtu daxil edin",
	邮箱已存在:"EMAIL artıq var",
	注册邮箱已存在:"Qeydə alınmış e-poçt artıq mövcuddur",
	指定用户不存在:"Göstərilən istifadəçi mövcud deyil",
	今日涨幅:"Dəyişmək",
	恭喜新用户:"Təbriklər yeni istifadəçi ",
	快捷充币:"Əmanət",
	快速买USDT:"USDT əmanəti",
	在线支付:"Onlayn Ödəniş",
	自动到账:"Avtomatik olaraq kreditləşdirilir",
	线下支付:"Oflayn ödənişlər",
	联系客服获取验证码:"Doğrulama kodunu əldə etmək üçün müştəri xidməti ilə əlaqə saxlayın",
	获取:"əldə edin",
	退出成功:"Uğurla çıxın",
	不能低于最小充值:"Minimum şarjdan aşağı ola bilməz",
	不能低于最小提现金额:"Minimum pul çıxarma məbləğindən aşağı ola bilməz",
	最小提现:"Minimum geri çəkilmə",
	设置成功:"Quraşdırma uğurlu",
	官方充币:"Rəsmi depozit",
	银行卡充值:"Bank əmanəti",
	等待审核:"Baxış gözlənilir",
	可提现金额不足:"Çıxarmaq üçün kifayət qədər məbləğ yoxdur",
	未经授权:"İcazəsiz",
	交易密码不正确:"Tranzaksiya parolu yanlışdır",
	提现次数不足:"Qeyri-kafi geri çəkilmə vaxtları",
	官网:"rəsmi saytı",
	账户usdt不足:"Hesab USDT balansı kifayət deyil",
	激活成功: "POS müvəffəqiyyəti",
	操作成功: "Uğurlu əməliyyat",
	修改成功:"Uğurla dəyişdirildi",
	注册说明:"Mobile OTP and Email CAPTCHA only need to choose 1 method to verify and you can register successfully",
	弹窗内容:
	"Plus500Ai kəmiyyət ticarət platformasına xoş gəlmisiniz. Hər bir istifadəçi 1 USDT qeydiyyat bonusundan istifadə edərək 1 gün ərzində etibarlı olan NO.0 kəmiyyət robot ticarəti strategiyası paketini əldə edə və 0,5 USDT pulsuz qazana bilər. Digər kəmiyyət ticarət strategiyası paketləri müvafiq ödənişlərin istifadə edilməsini tələb edir. İstifadəçilər robotu satın aldıqdan sonra avtomatik işə başlamazdan əvvəl kəmiyyət ticarət strategiyasını aktivləşdirməlidirlər.Hər bir ticarət strategiyası fərqli etibarlılıq müddətinə uyğun gəlir. İstifadəçi müvafiq robot xallarını aldıqdan sonra strategiyanı aktivləşdirmək üçün klikləməzsə, siz heç bir ticarət gəliri əldə etməyəcəksiniz.",
	标题1:'1.Plus500 platformasının təqdimatı',
	标题2:'2. AI kəmiyyət ticarətinin üstünlükləri',
	标题3:'3. Nə üçün Plus500Ai seçirsiniz?',
	标题4:'4. Plus500Ai-yə necə qoşulmaq olar',
	标题5:'5.Plus500Ai bazar perspektivləri',
	标题6:'6.Plus500Ai gəlir modeli',
	标题7:'7. Depozit və Çıxarma Qaydaları',
	标题8:"Döyüş Royale Oyunu",
	内容8:"Plu500Ai platformasındakı battle royale oyununda 8 otaq var.Oyunçular qızıl sikkələr qoymaq üçün 8 otaq arasından istənilən otağı seçə bilərlər.Sistem geri sayım bitdikdən sonra oyun sistemində killer peyda olacaq.Qatil təsadüfi olaraq otağa daxil olur. və otaqdakı insanları aradan qaldırır.Digər oyunçular bu otaqda qızıl sikkələri paylaşıb qazanc əldə edə bilərlər. Battle royale oyununun oyunu nisbətən qısadır, oyun cəmi bir neçə dəqiqə davam edir, buna görə oyunçular onu boş vaxtlarında oynaya bilərlər. Bölmə miqdarı = bu dövrdə qatil tərəfindən öldürülən otaqların ümumi sayı. Faktiki payın məbləği = payın məbləği * 0,9 (məhv əmsalı 0,1 istisna olmaqla). Pay nisbəti = faktiki pay məbləği / digər otaqlardakı ümumi mərc məbləği. Uduş məbləği = paylaşma nisbəti * faktiki mərc məbləği. 1 qızıl sikkə = 1 USDT və battle royale oyununda iştirak etmək üçün minimum 10 USDT tələb olunur.",
	内容1:'Plus500 ticarət platforması Plus500SEY Ltd tərəfindən təmin edilir. Müvafiq olaraq, Plus500SEY Ltd bu internet saytında təsvir edilən və ya təklif olunan maliyyə məhsullarının emitenti və satıcısıdır. Plus500SEY Ltd, Viktoriyada ofisləri olan Seyşel adalarında yerləşən şirkətdir (Şirkət № 8426415-1). Şirkət SD039 lisenziya nömrəsi ilə Seyşel Adaları Maliyyə Xidmətləri Təşkilatı (FSA) tərəfindən icazə verilir və nəzarət edilir və müxtəlif əsas məhsullar üzrə Fərq Müqavilələri (CFD) təmin edir. Şirkət hazırda 2800-dən çox maliyyə alətindən ibarət portfel təklif edən sürətlə inkişaf edən CFD provayderidir. Plus500SEY Ltd London Birjasının Əsas Bazarında siyahıya alınmış və baş ofisi Hayfada yerləşən Plus500 Ltd-nin törəmə şirkətidir.',
	内容2:'Süni intellektlə tam avtomatlaşdırılmış kəmiyyət ticarətinin üstünlüyü model parametrlərinə uyğun olaraq əməliyyatları idarə etmək üçün rasional alətlərdən istifadə etməkdir.Robotun emosional dəyişikliyi yoxdur və insan təbiəti, insan hərisliyi və qorxusu və əməliyyat prosesində bir çox digər çatışmazlıq və zəifliklərə qalib gələ bilir. Əl əməliyyatları tez-tez bazarın yüksəlişi və enişi və müxtəlif digər amillərdən təsirlənir və uzun müddət ən yaxşı mənfəət modelinə uyğun olaraq fəaliyyət göstərə bilməz.',
	内容3:'Plus500Ai, əsas məhsullarını yeni və mövcud bazarlara genişləndirməklə, yeni ticarət və maliyyə məhsulları təqdim etməklə və müştərilərlə əlaqəni dərinləşdirməklə çox aktivli fintech qrupu kimi mövqeyini tədricən möhkəmləndirdi. Aktivlərinizi qoruyun və şifrələmə açarlarınızı sənayedə qabaqcıl təhlükəsizlik texnologiyası ilə qoruyun. Plus500Ai həmçinin qlobal saxlama təklif edir, yəni biz 250 milyon dollara qədər tənzimlənmiş, yoxlanılmış və sığortalanmışıq. Kapitalınızı yalnız aktivləri saxlamaq deyil, onları işə salmaq məqsədi ilə yerləşdirin. Plus500Ai sizə risklərə dözümlülüyündən asılı olaraq Ai kəmiyyət ticarətindən klassik əsas xidmətlərə qədər müxtəlif yollarla vəsait yerləşdirməyə imkan verir. Əlavə blokçeyn mühəndisləri işə götürmədən öz istifadəçilərinizə daha çox xidmət göstərmək üçün onlayn platforma.',
	内容4:'Plus500Ai yaradılandan bəri yalnız bir rəsmi internet saytı var, WWW.PLUS500AI.COM. Bütün istifadəçilər APP-ni yükləmək üçün rəsmi vebsayta daxil olmalıdırlar, çünki digər saytlar bizim şirkətimiz deyil. Qeydiyyatdan keçmək üçün tez qeydiyyatdan keçmək üçün istifadəçinin mobil telefon nömrəsini, e-poçt ünvanını və tövsiyə edənin dəvət kodunu təqdim etməlisiniz. Müştərilərlə bütün ünsiyyətlər yazılı şəkildə, ya e-poçt və ya onlayn söhbət vasitəsilə aparılır. Plus500 ilə e-poçt vasitəsilə əlaqə saxlamaq üçün Bizimlə əlaqə səhifəsindəki formanı doldurun (Sorğu Forması). Ərizə formasını təqdim etdikdən sonra Plus500 ərizə formasında göstərdiyiniz e-poçt ünvanına birbaşa e-poçt vasitəsilə cavab göndərəcək. Hər hansı bir sualınız varsa, müştəri xidməti ilə əlaqə saxlayın.',
	内容5:'Plus500Ai müştərilərə etibarlı və intuitiv maliyyə ticarəti imkanları təqdim etmək üçün məhsullar, cihazlar və ölkələr üzrə fəaliyyət göstərir.Biz məhsullarımızda və komandalarımızda həmişə mükəmməlliyə can atmışıq. Qabaqcıl mülkiyyət texnologiyası və müştəri mərkəzli yanaşma ilə istifadəçilərimiz birinci dərəcəli ticarət xidməti təcrübəsi əldə edirlər.',
	内容6:'Plus500Ai yüksək səviyyəli məlumat analitiklərindən ibarət bir qrup tərəfindən kriptovalyuta bazarı üçün peşəkar kəmiyyət ticarət strategiyaları təqdim edir.Ai kəmiyyət ticarət robotuna sərmayə qoyan istifadəçilər robotla uyğunlaşdırılmış ticarət strategiyalarına əsaslanaraq kəmiyyət ticarət mənfəəti əldə edə bilərlər. Ai kəmiyyət ticarət robotu Plus500Ai-nin bazar ticarət strategiyasına əsaslanaraq optimal ticarət vaxtını dövri olaraq təhlil edir və yüksək qiymətlərlə aşağı səviyyəli alış-satış modeli vasitəsilə kəmiyyət ticarət mənfəəti əldə edir. Plus500Ai investisiya edən istifadəçilərə hər gün optimal kəmiyyət ticarət strategiyaları təqdim edir və platforma istifadəçilərin kəmiyyət gəlirlərinin 5%-ni platforma əməliyyat fondları kimi çıxarır.',
	内容7:'Plus500Ai hazırda USDT əmanətlərini və pul çəkmələrini dəstəkləyir və gələcəkdə digər kanalların açılacağı təqdirdə rəsmi internet saytında bu barədə elan olacaq. Minimum əmanət və çıxarma 10 USDT-dir.Ən sürətli depozit və çıxarma 5 dəqiqə ərzində, ən yavaşı isə 24 saat ərzindədir. İstifadəçinin pul çıxarma müddətində heç bir məhdudiyyət yoxdur, istənilən vaxt pul çıxara bilərsiniz. Çıxarmalar 5 dəqiqə - adi iş günlərində 24 saat ərzində çatır. Həftə sonu pul vəsaitlərinin çıxarılması bazar ertəsi günü həyata keçiriləcək.',
	BSC提现:"USDT_BSC çıxarılması",
	社区Ads收益:"İcma reklamlarından gəlir",
	Ads收益区间:"Reklam gəliri 1-1000$",
	申请Ads费用:"Tətbiq reklamları haqqı",
	Ads促销地址:"Reklamların təşviq ünvanı",
	填写Ads促销地址:"Reklam təşviqi URL-ni doldurun",
	申请Ads费用须知:"Reklam haqqı üçün müraciət haqqında qeydlər",
	须知A:"1. İstifadəçilər reklam videoları çəkir və onları Reklamların təşviqi üçün öz YouTube, Facebook, kanalları və ya digər icmalarına yükləyirlər, $1-1000 bonus üçün müraciət edirlər və platforma ərizəni təqdim etdikdən 3 gün sonra Reklamlar video materialınızı nəzərdən keçirəcək və yerləşdirəcək. Video təşviq effektinə görə hesaba Reklam bonusu Hesab balansınız, heç bir tanıtım effekti olmayan videolara baxıla bilməz",
	须知B:"2. Eyni kanalda eyni Elan tanıtım videosu yalnız bir dəfə bonus üçün müraciət edə bilər və həftədə bir dəfə yeni tanıtım video materialları buraxıla bilər. Zərərli təkrar sorğular göndərməyin. Platformada özünüz tərəfindən edilməmiş Reklam sorğularını və ya nəzərdən keçirilməyən Reklam materiallarını qəsdən təqdim etsəniz, sistem aşkar etdikdən sonra hesabınız bloklanacaq.",
	须知C:"3. İstifadəçi tərəfindən qeydə alınan Reklamlar video materialında əsas platforma təqdimatı və platforma funksiyasının izahı olmalıdır. Videonun müddəti 5 dəqiqədən az deyil. Kanalınıza nə qədər çox abunə olarsa, videonun tanıtım effekti bir o qədər yaxşı olar və müraciət edə biləcəyiniz Reklam haqqı bir o qədər yüksək olar. Nümunə olaraq YOUTUBE-u götürən xüsusi Reklam haqları aşağıdakı kimidir:",
	须知D: "Abunəçi 0-2K: ərizə məbləği 1-20$",
	须知D1:"Abunəçi 3K-10K: ərizə məbləği 21-40$",
	须知D2:"Abunəçi 11K-30K: ərizə məbləği 41-100$",
	须知D3:"Abunəçi 31K-50K: ərizə məbləği 101-300$",
	须知D4:"Abunəçi 51K-100K: ərizə məbləği 301-1000$",
	须知D5:"4. Sistem hər bazar günü Reklamlar üçün bonus müraciətlərini nəzərdən keçirəcək və emal edəcək və ixtisaslı istifadəçilər sistem tərəfindən göndərilən bonusları alacaqlar.",
	请求审核:"Baxış üçün sorğu",
	申请金额:"Ərizə məbləği",
	URL促销地址:"URL Tanıtım ünvanı",
	提交时间:"Göndərmə vaxtı",
	需要更新的银行卡不存在:"Yenilənəcək bank kartı mövcud deyil",
	预计收入:"Hesablanmış gəlir",
	总投资:"Ümumi investisiya",
	购买价:"Alış Qiyməti",
	邀请:"Dəvət edin",
	积分等级折扣规则介绍3:"Bu endirim məbləği yalnız 31 dekabr 2023-cü ilə qədər etibarlıdır, 1 yanvar 2024-cü ildən sonra yeni düzəliş olacaq.",
	成员:"üzvləri",
	LV1佣金:"Səviyyə1 Komissiyası",
	LV2佣金:"Səviyyə2 Komissiyası",
	LV3佣金:"Səviyyə3 Komissiyası",
	合格条件:"Əmanət adambaşına minimum 8$",
	随时可退:"İstənilən vaxt geri qaytarın",
	登录密码错误:"Səhv giriş parolu",
	充值奖金:"Depozit USDT ≥ 500USDT, bonus 3%",
	充值奖金1:"Depozit IDR ≥ 50USDT, bonus 2%",
	充值奖金2:"Depozit THB ≥ 50USDT, bonus 5% ",
	活动已过期:"Tədbirin müddəti bitdi, növbəti tədbiri gözləyir",
	不能重复发送短信:"Siz 10 dəqiqə ərzində təkrar mətn mesajları göndərə bilməzsiniz. Birbaşa qeydiyyatdan keçmək üçün EMAIL OTP-dən istifadə etməyiniz tövsiyə olunur.",
	限购说明1:"Salam, NO.0 kəmiyyət robot ticarət strategiyası paketinin hər bir istifadəçisi onu yalnız bir dəfə ala bilər.",
	修改银行提示:"Çıxarılan bank hesabı bağlandıqdan sonra dəyişdirilə bilməz. Yardıma ehtiyacınız varsa, müştəri xidməti ilə əlaqə saxlayın.",
	修改钱包地址提示:"Əlaqədar USDT pul kisəsi ünvanını dəyişdirmək lazımdırsa, yoxlama üçün müştəri xidməti ilə əlaqə saxlayın.",
	注册推送消息1:"INGSAI-a xoş gəlmisiniz. Yeni istifadəçilər POS mədən qovşağına başladıqdan sonra hər 5 dəqiqədən bir POS mədən gəliri alacaqlar. Komandanın komissiya gəliri avtomatik olaraq gündə təxminən 00.00-da hesab balansına daxil ediləcək.",
	限购说明:"Salam, bu növ kəmiyyət robot paketi üçün alışların maksimum sayı 10 dəfədir.Digər növ kəmiyyət ticarət paketlərini almağınız tövsiyə olunur.",
	注册协议:"(Hesab yarat) düyməsini sıxmaqla 18 yaşdan yuxarı olduğunuzu təsdiq edirsiniz. Siz bilirsiniz ki, Plus500AI məhsulları/xidmətləri haqqında müvafiq məlumatları təqdim etmək üçün sizinlə elektron əlaqə saxlamaq üçün e-poçt ünvanınızdan və ya digər şəxsi məlumatlarınızdan istifadə edə bilər. Ticarət təcrübənizdən maksimum yararlanmağınıza kömək etmək üçün sizə bazar hadisələri haqqında da məlumat verə bilərik. Siz Platformada (Bildiriş Parametrləri) və (Məxfilik Parametrləri) nişanlarında bildiriş seçimlərini idarə edə və dəyişə bilərsiniz. İstənilən vaxt bu kommunikasiyalara abunəlikdən çıxa bilərsiniz. Əlavə məlumat üçün (Məxfilik Müqaviləsi) nəzərdən keçirin",
	公司说明1:"Plus500AI Plus500 Ltd-nin qeydə alınmış ticarət nişanıdır. Plus500 Ltd aşağıdakı törəmə şirkətlər vasitəsilə fəaliyyət göstərir:",
	公司说明2:"Plus500SEY Ltd Seyşel Adaları Maliyyə Xidmətləri Təşkilatı tərəfindən icazə verilir və tənzimlənir (Lisenziya No. SD039).",
	公司说明3:"Plus500SEY Ltd bu internet saytında təsvir olunan və ya mövcud olan maliyyə məhsullarının emitenti və satıcısıdır.",
	公司说明4:"Plus500UK Ltd Maliyyə Davranışı Təşkilatı tərəfindən səlahiyyətli və tənzimlənir. Financial Conduct Authority Qeydiyyat Nömrəsi: 509909. Kriptovalyuta CFD-ləri pərakəndə istifadəçilər üçün əlçatan deyil.",
	公司说明5:"Plus500CY Ltd Kipr Qiymətli Kağızlar və Birja Komissiyası tərəfindən icazə verilir və tənzimlənir (icazə kodu: 250/14). Böyük Britaniyanın pərakəndə investorları üçün kriptovalyuta CFD-ləri mövcud deyil.",
	公司说明6:"Plus500AU Pty Ltd aşağıdakı lisenziyalara malikdir: ASIC tərəfindən verilmiş AFSL (No. 417727), Yeni Zelandiya FMA tərəfindən verilmiş FSP (No. 486026) və Cənubi Afrika FSCA tərəfindən verilmiş Səlahiyyətli Maliyyə Xidmətləri Provayderi (No. 47546).",
	公司说明7:"Plus500EE AS ​​Estoniya Maliyyə Nəzarəti Təşkilatı tərəfindən icazə verilir və tənzimlənir (Lisenziya № 4.1-1/18).",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) Sinqapur Valyuta Təşkilatı tərəfindən verilmiş kapital bazarı xidmətləri lisenziyasına malikdir və ona kapital bazarı məhsulları ilə ticarət etməyə imkan verir (lisenziya nömrəsi CMS100648).",
	公司说明9:"Plus500AE Ltd Dubay Maliyyə Xidmətləri İdarəsi (F005651) tərəfindən səlahiyyətli və tənzimlənir.",
    加入我们的社群:"Cəmiyyətimizə qoşulun",
	PLUS500集团:"PLUS500 QRUPU",
	受监管的操作:"TƏNZİMLƏNƏN ƏMƏLİYYATLAR",
	健全的全球监管框架:"Güclü qlobal tənzimləyici çərçivə",
	治理和社会责任:"İDARƏETMƏ VƏ SOSİAL MƏSULİYYƏT",
	底部说明:"Plus500 öz biznesinin bütün aspektlərində məsuliyyətli və davamlı fəaliyyət göstərməyə sadiq qalır və bu yanaşmanın həm onun vəzifəsi, həm də effektiv idarəetmənin vacib hissəsi olduğuna inanır. Plus500 xalqımız, müştərilərimiz, yerli icmalar və xeyriyyə təşkilatları və səhmdarlarımız üçün maddi dəyər yaratmaq üçün bir sıra ESG təşəbbüslərinə sadiqdir",
	投资者联系方式:"İNVESTOR ƏLAQƏLƏRİ",
	投资者关系主管:"İnvestorlarla Əlaqələr şöbəsinin rəhbəri",
	AI智慧型量化交易:"AI ağıllı kəmiyyət ticarəti",
	订单执行快速可靠:"Sifarişin icrası sürətli və etibarlıdır",
	全天候自动分析行情:"Gün ərzində avtomatik bazar təhlili",
	交易受监管低风险:"Ticarət tənzimlənir və aşağı risklidir",
	稳定策略高效收益:"Sabit strategiya və səmərəli gəlirlər",
	取款快速且安全:"Pulunuzu tez və təhlükəsiz şəkildə çıxarın",
	游戏说明:"Oyuna daxil olmaq və pul qazanmaq üçün tələb olunan minimum balans 10 USDT-dir",
}
